/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from "react";
import { ButtonComponent } from "../ButtonComponent";
import {
  AddBtn,
  AddDocument,
  Label,
  NotAddedText,
  Pointer,
  Required,
  UploadInput,
  UploadSubtext,
  UploadText,
} from "./style";
import { Form, Formik, FormikHelpers } from "formik";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { DropdownComponent } from "../DropdownComponent";
import { FormInput } from "../FormInput";
import DocumentCard from "./DocumentCard";
import DocumentModal from "../DocumentModal";
import { uploadImage } from "../../firebase/firebase";
import { debounce } from "debounce";
import {
  createDocument,
  createSchool,
  dataURLtoFile,
  getSchools,
  getStudentDocById,
  optimizeImage,
  optimizePDF,
  searchCSchools,
  searchCountries,
  truncateString,
  updateDocument,
} from "../../Services/Function";
import { EducationForm } from "../EducationForm";
import ExperienceForm from "../ExperienceForm/ExperienceForm";
import { ETestForm } from "../ETestForm/ETestForm";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useState } from "react";

interface IDocumentPageProps {
  courseLevels: any;
  documents?: any;
  setdocuments?: any;
}

export const DocumentPage: React.FunctionComponent<IDocumentPageProps> = (
  props
) => {
  interface Values {
    documentType: string;
    subType: string;
    tag: object;
    blobInfo: Array<object>;
  }

  const { documents, setdocuments } = props;
  const [noDocument, setnoDocument] = React.useState(false);
  const [steps, setsteps] = React.useState<any>(1);
  const [modalShow, setmodalShow] = React.useState(false);
  const [documentType, setdocumentType] = React.useState("");
  const [testCat, settestCat] = React.useState("");
  const [imageAsFile, setimageAsFile] = React.useState<any>([]);
  const [imageAsUrl, setImageAsUrl] = React.useState<(string | ArrayBuffer)[]>(
    []
  );
  const [selectedCountry, setselectedCountry] = React.useState<any>([]);
  const [searchText, setsearchText] = React.useState("");
  const [countries, setcountries] = React.useState([]);
  const [selectedqualification, setselectedqualification] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [startMonth, setstartMonth] = React.useState(new Date());
  const [startYear, setstartYear] = React.useState(new Date());
  const [endMonth, setendMonth] = React.useState(new Date());
  const [endYear, setendYear] = React.useState(new Date());
  const [selectedGradingSystem, setselectedGradingSystem] = React.useState("");
  const [leavingDate, setleavingDate] = React.useState("");
  const [joiningDate, setjoiningDate] = React.useState("");
  const [issueDate, setissueDate] = React.useState(new Date());
  const [validDate, setvalidDate] = React.useState(new Date());

  const [subType, setsubType] = React.useState("");
  const [selectedSchool, setselectedSchool] = React.useState<any>({});
  const [schools, setschools] = React.useState([]);
  const [schoolSearch, setschoolSearch] = useState("");
  const [addSchool, setaddSchool] = useState(false);
  const [tempDocs, settempDocs] = useState<any>([]);
  const [uploading, setuploading] = useState(null);

  const [filePreviewUrls, setFilePreviewUrls] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  const [isImage, setIsImage] = React.useState<boolean[]>([]);
  const [isPdf, setIsPdf] = React.useState<boolean[]>([]);

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const addDoc = queryParams.get("addDoc");

  React.useEffect(() => {
    async function fetchData() {
      if (addDoc === "true") {
        setsteps(2);
      } else {
        setnoDocument(false);
      }

      if (tempDocs.length > 0) {
        uploadFilesInBackground();
      }

      const school = await getSchools({});
      setschools(school);

      setnoDocument(documents.length === 0);
    }
    fetchData();
  }, [tempDocs]);

  const uploadFilesInBackground = async () => {
    // Iterate through temporary documents and upload files

    for (const documentInfo of tempDocs) {
      setuploading(documentInfo.id);
      const downloadURLs = await handleFireBaseUpload();

      // Update the blobInfo with download URLs
      documentInfo.blobInfo = downloadURLs?.map((url: any) => ({
        blobUrl: url.url,
        name: url.name,
      }));
      documentInfo.docState = downloadURLs?.length ? "Upload" : "Not Uploaded";
      // Update the document in the database with the Firebase download URLs
      await updateDocument(documentInfo.id, documentInfo);

      // Remove the processed document from temporary storage
      settempDocs((prevDocuments: any) =>
        prevDocuments.filter((doc: any) => doc !== documentInfo)
      );
    }
    setuploading(null);
    setimageAsFile([]);
  };

  const reloadDocuments = async () => {
    const res = await getStudentDocById(id);
    setdocuments(res.data);
  };

  function handleRemoveImage(index: number) {
    setFilePreviewUrls(filePreviewUrls.filter((_a: any, key) => key !== index));
    setIsImage(isImage.filter((_a: any, key) => key !== index));

    setIsPdf(isPdf.filter((_a: any, key) => key !== index));

    setimageAsFile(imageAsFile.filter((_a: any, key: number) => key !== index));
  }
  async function handleImageAsFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          setFilePreviewUrls((prevFilePreviewUrls: any) => [
            ...prevFilePreviewUrls,
            reader.result,
          ]);
          setIsImage((prevIsImage: any) => [
            ...prevIsImage,
            selectedFile.type.includes("image"),
          ]);
          setIsPdf((prevIsPdf: any) => [
            ...prevIsPdf,
            selectedFile.type.includes("pdf"),
          ]);

          // Directly add the file to the state without optimization
          setimageAsFile((prev: any) => [...prev, selectedFile]);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const handleFireBaseUpload = async () => {
    if (imageAsFile) {
      const downloadURL = await uploadImage(imageAsFile);
      return downloadURL;
    }
  };

  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setcountries(res?.results);
    }
  }, 500);

  const searchSchool = debounce(async (e: any) => {
    if (!e.target.value) {
      setschools([]);
    } else {
      const res = await searchCSchools({ text: e.target.value });
      setschools(res?.results);

      // If the entered value is not found in the results
    }
  }, 500);

  const NoDocComponent = () => {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column h-100">
        <div
          style={{
            backgroundImage: `url(${"https://storage.googleapis.com/uapply/no-document.png"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: 230,
            height: 205,
            borderRadius: 8,
          }}
        />
        <NotAddedText>Documents aren’t added yet</NotAddedText>
        <AddDocument
          onClick={() => {
            setsteps(2);
          }}
        ></AddDocument>
      </div>
    );
  };

  const qualification = [
    "Diploma",
    "High School Transcript",
    "Postgraduate",
    "Undergraduate",
    "Other",
  ];

  const gradingSystem = ["Percentage", "GPA"];

  console.log(
    documents.filter((z: any) => z.documentType.toLowerCase() === "education")
  );
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 16 }}
      >
        <>Documents</>
        <div className="d-flex flex-row">
          <ButtonComponent
            style={{ borderRadius: 20, width: "max-content" }}
            btnType="dark"
            onClick={() => {
              setsteps(2);
            }}
            loading={false}
            label="+ Add Documents"
          />
        </div>
      </div>
      {steps === 1 && !documents.length ? (
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <div
            style={{
              backgroundImage: `url(${"https://storage.googleapis.com/uapply/no-document.png"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: 230,
              height: 205,
              borderRadius: 8,
            }}
          />
          <NotAddedText>Documents aren’t added yet</NotAddedText>
          <AddDocument
            onClick={() => {
              setsteps(2);
            }}
          >
            <AddBtn>+</AddBtn> Add documents
          </AddDocument>
        </div>
      ) : steps === 2 ? (
        <Formik
          initialValues={{
            documentType: "",
            subType: "",
            tag: {},
            blobInfo: [],
            discipline: "",
            institution: "",
            score: "",
            institute: "",
            testName: "",
            organisationName: "",
            jobTitle: "",
            desc: "",
          }}
          onSubmit={async (
            values: {
              documentType: string;
              subType: string;
              tag: {};
              blobInfo: never[];
              discipline: string;
              institution: string;
              score: string;
              institute: string;
              testName: string;
              organisationName: string;
              jobTitle: string;
              desc: string;
            },
            formikHelpers: FormikHelpers<{
              documentType: string;
              subType: string;
              tag: {};
              blobInfo: never[];
              discipline: string;
              institution: string;
              score: string;
              institute: string;
              testName: string;
              organisationName: string;
              jobTitle: string;
              desc: string;
            }>
          ) => {
            const resetStates = () => {
              // Reset state values
              setselectedCountry({});
              setdocumentType("");
              setendMonth(new Date());
              setendYear(new Date());
              setstartMonth(new Date());
              setstartYear(new Date());
              setselectedGradingSystem("");
              setselectedqualification("");
              values.discipline = "";
              values.institute = "";
              values.score = "";
              setissueDate(new Date());
              values.testName = "";
              values.score = "";
              setjoiningDate("");
              setleavingDate("");
              values.jobTitle = "";
              values.organisationName = "";
              setsubType("");
              values.desc = "";
            };
            setloading(true);
            // let downloadURL: any = await handleFireBaseUpload();
            const documentData = [];

            switch (documentType) {
              case "Education":
                documentData.push({
                  documentType: documentType.replace(/[\s/]/g, ""),
                  tag: {
                    country: selectedCountry.english_name,
                    discipline: values.discipline,
                    duration: 3,
                    endMonth,
                    endYear,
                    gradingSystem: selectedGradingSystem,
                    institution: Object.keys(selectedSchool).length
                      ? selectedSchool?.english_name
                      : values.institute,
                    score: values.score,
                    startMonth,
                    startYear,
                    qualification: selectedqualification,
                  },
                  // docState: downloadURL?.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              case "English Entrance Test":
                documentData.push({
                  documentType: "EnglishEntranceTest",
                  tag: {
                    issueDate: moment(issueDate).format(),
                    testName: testCat === "Other" ? values.testName : testCat,
                    score: values.score,
                    validDate: moment(validDate).format(),
                  },
                  // docState: downloadURL.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              case "Professional Experience":
                documentData.push({
                  documentType: documentType.replace(/[\s/]/g, ""),
                  tag: {
                    startDate: moment(joiningDate).format(),
                    endDate: moment(leavingDate).format(),
                    currentCompany: !leavingDate,
                    companyName: values.organisationName,
                    jobTitle: values.jobTitle,
                  },
                  // docState: downloadURL.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              case "Travel":
                documentData.push({
                  documentType: documentType.replace(/[\s/]/g, ""),
                  subType,
                  tag: { name: values.desc },
                  // docState: downloadURL.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              case "Finance":
                documentData.push({
                  documentType: documentType.replace(/[\s/]/g, ""),
                  subType: "finance",
                  tag: { name: values.desc },
                  // docState: downloadURL.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              case "Other":
                documentData.push({
                  documentType: documentType.replace(/[\s/]/g, ""),
                  subType: "Other",
                  tag: { name: values.desc },
                  // docState: downloadURL.length ? "Upload" : "Not Uploaded",
                  blobInfo: [],
                  studentId: id,
                });
                break;

              default:
                break;
            }

            try {
              const documentCreationPromises = documentData.map(
                async (data) => {
                  const res = await createDocument(data);
                  settempDocs((prevDocuments: any) => [...prevDocuments, res]);
                }
              );

              await Promise.all(documentCreationPromises);

              // Reset all states together after the document creation
              resetStates();
              setFilePreviewUrls([]);
            } catch (error) {
              // Handle errors
            } finally {
              setloading(false);
            }

            // downloadURL = [];
            reloadDocuments();
            setschools([]);
            setsteps(1);
            setselectedSchool("");
            setloading(false);
            setnoDocument(false);
            setFilePreviewUrls([]);
          }}
        >
          <Form style={{ width: "90%" }} className="d-flex flex-column">
            <>
              <Col md={6} style={{ marginBottom: 20 }}>
                <Label>
                  <Required>*</Required> Type of Document
                </Label>
                <DropdownComponent
                  children={[
                    "Education",
                    "English Entrance Test",
                    "Professional Experience",
                    "Extracurricular",
                    "Travel",
                    "Finance",
                    "Other",
                  ]}
                  selected={documentType}
                  onClick={(a: string) => {
                    setdocumentType(a);
                  }}
                  className={""}
                  style={{}}
                  placeholder="Select"
                  strArr
                />
              </Col>

              {documentType === "English Entrance Test" ? (
                <>
                  <ETestForm
                    setissueDate={setissueDate}
                    issueDate={issueDate}
                    settestCat={settestCat}
                    testCat={testCat}
                    setvalidDate={setvalidDate}
                  />
                </>
              ) : null}

              {documentType === "Education" ? (
                <>
                  <EducationForm
                    selectedCountry={selectedCountry}
                    selectedSchool={selectedSchool}
                    setselectedSchool={setselectedSchool}
                    schools={schools}
                    setschools={setschools}
                    setsearchText={setsearchText}
                    setselectedCountry={setselectedCountry}
                    setaddSchool={setaddSchool}
                    addSchool={addSchool}
                    setschoolSearch={setschoolSearch}
                    schoolSearch={schoolSearch}
                    searchCountry={searchCountry}
                    searchSchool={searchSchool}
                    countries={countries}
                    setcountries={setcountries}
                    searchText={searchText}
                    setselectedqualification={setselectedqualification}
                    selectedqualification={selectedqualification}
                    qualification={qualification}
                    setstartMonth={setstartMonth}
                    setstartYear={setstartYear}
                    setendYear={setendYear}
                    setselectedGradingSystem={setselectedGradingSystem}
                    selectedGradingSystem={selectedGradingSystem}
                    setendMonth={setendMonth}
                    gradingSystem={gradingSystem}
                  />
                </>
              ) : null}

              {documentType === "Professional Experience" ? (
                <>
                  <ExperienceForm
                    joiningDate={joiningDate}
                    setjoiningDate={setjoiningDate}
                    leavingDate={leavingDate}
                    setleavingDate={setleavingDate}
                  />
                </>
              ) : null}

              {documentType === "Travel" ? (
                <>
                  <Col md={6} style={{ marginBottom: 20 }}>
                    <DropdownComponent
                      children={["Passport", "Visa", "Civil Id"]}
                      selected={subType}
                      onClick={(a: string) => {
                        setsubType(a);
                      }}
                      className={""}
                      style={undefined}
                      placeholder="Select"
                      strArr
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 20 }}>
                    <FormInput
                      id="desc"
                      style={{ borderRadius: 3, marginBottom: 0 }}
                      name="desc"
                      label="Description"
                      placeholder=""
                      type="text"
                    />
                  </Col>
                </>
              ) : null}

              {documentType === "Finance" || documentType === "Other" ? (
                <>
                  <Col md={6} style={{ marginBottom: 20 }}>
                    <FormInput
                      id="desc"
                      style={{ borderRadius: 3, marginBottom: 0 }}
                      name="desc"
                      label="Description"
                      placeholder=""
                      type="text"
                    />
                  </Col>
                </>
              ) : null}

              {
                <Col md={6} style={{ marginBottom: 20 }}>
                  <UploadInput>
                    <input
                      style={{
                        height: "100%",
                        opacity: 0,
                        width: "100%",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                      onChange={(e: any) => handleImageAsFile(e)}
                    />

                    <UploadText>Upload Document</UploadText>
                    <UploadSubtext>
                      Drag and drop your file here or click to upload a local
                      file
                    </UploadSubtext>
                  </UploadInput>
                  {filePreviewUrls.length > 0 && (
                    <div style={{ marginTop: 10 }} className="d-flex flex-row">
                      {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                        return (
                          <div style={{ marginRight: 5 }} key={i}>
                            <div
                              onClick={() => {
                                handleRemoveImage(i);
                              }}
                              style={{
                                position: "absolute",
                                cursor: "pointer",
                                fontSize: 12,
                                fontWeight: 700,
                                background: "#fff",
                                width: 20,
                                height: 20,
                                color: "red",
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              x
                            </div>
                            {isImage[i] ? (
                              <div
                                style={{
                                  backgroundImage: `url(${filePreviewUrl})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  width: 70,
                                  height: 70,
                                  borderRadius: 8,
                                }}
                              />
                            ) : (
                              <p style={{ marginLeft: 25 }}>
                                {truncateString(imageAsFile[i]?.name)}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Col>
              }
              {
                <Row>
                  <ButtonComponent
                    label={"Submit"}
                    loading={loading}
                    disabled={loading || !documentType}
                    style={{
                      borderRadius: 20,
                      marginBottom: 20,
                      width: "max-content",
                    }}
                    btnType="dark"
                  />

                  <ButtonComponent
                    label={"Exit"}
                    loading={loading}
                    onClick={() => setsteps(1)}
                    disabled={loading}
                    style={{
                      borderRadius: 20,
                      marginLeft: 10,
                      marginBottom: 20,
                      width: "max-content",
                    }}
                    btnType="light"
                  />
                </Row>
              }
            </>
          </Form>
        </Formik>
      ) : null}

      {steps === 1 && documents.length ? (
        <Tabs defaultActiveKey="education" id="uncontrolled-tab-example">
          <Tab eventKey="education" title="Education">
            {!documents.filter(
              (z: any) => z.documentType.toLowerCase() === "education"
            ).length ? (
              <NoDocComponent />
            ) : (
              <>
                {documents
                  .filter(
                    (z: any) => z.documentType.toLowerCase() === "education"
                  )
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.tag.qualification}
                          uploading={uploading}
                          document={a}
                          images={imageUrls}
                          reloadDocuments={reloadDocuments}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          data={[
                            {
                              text: a.tag.institution,
                              pointer: <Pointer />,
                              fontWeight: 600,
                            },
                            { text: a.tag.country, fontWeight: 600 },
                          ]}
                          data2={[
                            {
                              text: `${moment(a.tag.startYear).format(
                                "YYYY"
                              )}-${moment(a.tag.endYear).format("YYYY")}`,
                              pointer: <Pointer />,
                              fontWeight: 400,
                            },
                            { text: a.tag.discipline, fontWeight: 400 },
                          ]}
                          attachments="2"
                          score={a.tag.score}
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>

          <Tab eventKey="EnglishEntranceTest" title="English Entrance Test">
            {!documents.filter(
              (z: any) => z.documentType === "EnglishEntranceTest"
            ).length ? (
              <NoDocComponent />
            ) : (
              <>
                {documents
                  .filter((z: any) => z.documentType === "EnglishEntranceTest")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });
                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.tag.testName}
                          uploading={uploading}
                          reloadDocuments={reloadDocuments}
                          document={a}
                          images={imageUrls}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          data={[
                            {
                              text: `Score: ${a.tag.score}`,
                              pointer: <Pointer />,
                              fontWeight: 400,
                            },
                          ]}
                          data2={[
                            {
                              text: `Issued: ${moment(a.tag.issueDate).format(
                                "DD-MM-YYYY"
                              )}`,
                              pointer: <Pointer />,
                              fontWeight: 400,
                            },
                            {
                              text: `Valid till: ${moment(
                                a.tag.validDate
                              ).format("DD-MM-YYYY")}`,
                              fontWeight: 400,
                            },
                          ]}
                          attachments="2"
                          score={a.tag.score}
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>
          <Tab eventKey="Travel" title="Travel">
            {!documents.filter((z: any) => z.documentType === "Travel")
              .length ? (
              <NoDocComponent />
            ) : (
              <>
                {" "}
                {documents
                  .filter((z: any) => z.documentType === "Travel")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.subType}
                          data={[
                            {
                              text: `${a.tag.name}`,
                              fontWeight: 400,
                            },
                          ]}
                          uploading={uploading}
                          reloadDocuments={reloadDocuments}
                          document={a}
                          images={imageUrls}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          attachments="2"
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>

          <Tab eventKey="Finance" title="Finance">
            {!documents.filter((z: any) => z.documentType === "Finance")
              .length ? (
              <NoDocComponent />
            ) : (
              <>
                {" "}
                {documents
                  .filter((z: any) => z.documentType === "Finance")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.tag.name}
                          uploading={uploading}
                          reloadDocuments={reloadDocuments}
                          document={a}
                          images={imageUrls}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          attachments="2"
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>
          <Tab
            eventKey="ProfessionalExperience"
            title="Professional Experience"
          >
            {!documents.filter(
              (z: any) => z.documentType === "ProfessionalExperience"
            ).length ? (
              <NoDocComponent />
            ) : (
              <>
                {documents
                  .filter(
                    (z: any) => z.documentType === "ProfessionalExperience"
                  )
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.tag.companyName}
                          uploading={uploading}
                          reloadDocuments={reloadDocuments}
                          document={a}
                          images={imageUrls}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          data={[
                            {
                              text: a.tag.jobTitle,
                              pointer: <Pointer />,
                              fontWeight: 600,
                            },
                            {
                              text: `${moment(a.tag.startDate).format(
                                "DD-MM-YYYY"
                              )}-${
                                a.tag.currentCompany
                                  ? "Present"
                                  : moment(a.tag.endDate).format("DD-MM-YYYY")
                              }`,
                              fontWeight: 400,
                            },
                          ]}
                          attachments="2"
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>

          <Tab eventKey="Other" title="Other">
            {!documents.filter((z: any) => z.documentType === "Other")
              .length ? (
              <NoDocComponent />
            ) : (
              <>
                {" "}
                {documents
                  .filter((z: any) => z.documentType === "Other")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";
                    const wordExtensions = [".doc", ".docx"];

                    const imageUrls: any = [];
                    const pdfUrls: any = [];
                    const docsUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      } else if (wordExtensions.includes(extension)) {
                        docsUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <div key={key}>
                        <DocumentCard
                          name={a.subType}
                          data={[
                            {
                              text: `${a.tag.name}`,
                              fontWeight: 400,
                            },
                          ]}
                          uploading={uploading}
                          reloadDocuments={reloadDocuments}
                          document={a}
                          images={imageUrls}
                          pdf={pdfUrls}
                          docs={docsUrls}
                          attachments="2"
                          key={a.name}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </Tab>
        </Tabs>
      ) : null}
      {/* <div
        className="d-flex justify-content-between"
        style={{
          borderTop: "1px solid #eaecf0",
          padding: "32px 0",
          bottom: 0,
          width: "100%",
        }}
      >
        <BackBtn>{`< Back`}</BackBtn>
        <ButtonComponent
          style={{ borderRadius: 20, width: "max-content" }}
          btnType="dark"
          loading={false}
          label="Continue"
          onClick={() => {
            if (steps < 2) {
              setsteps(steps + 1);
            } else {
              setsteps(null);
              setnoDocument(false);
            }
          }}
        />
      </div> */}
      <DocumentModal
        courseLevels={props.courseLevels}
        show={modalShow}
        handleClose={() => setmodalShow(false)}
      />
    </div>
  );
};
