import React from "react";
import { Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

type Props = {
  pdf?: string;
  show: boolean;
  images?: string;
  handleClose: () => void;
  openViewer?: any;
  docs?: string; // This can include URLs for PDF, Word, and other document types
};

export const ViewerModal = (props: Props) => {
  const { pdf, show, images, handleClose, docs } = props;

  // Prepare document file(s)
  const docsToView = docs ? [{ uri: docs }] : [];

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        fullscreen
        className="modal fade doc-viewer-modal"
      >
        <Modal.Header style={{ color: "#fff" }} closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          {pdf ? (
            <iframe
              src={pdf}
              title="PDF Viewer"
              width="80%"
              height="100%"
            ></iframe>
          ) : docs ? (
            <DocViewer
              documents={docsToView}
              pluginRenderers={DocViewerRenderers}
              style={{ width: "80%", height: "100%" }}
            />
          ) : images ? (
            <div
              style={{
                backgroundImage: `url('${images}')`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                width: "80%",
                paddingTop: "50%",
                backgroundRepeat: "no-repeat",
                borderRadius: 8,
              }}
            />
          ) : (
            <p>No document available for viewing.</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
