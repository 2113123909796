import React from "react";
import { IHeader } from "./Header";
import { AvatarCon, NavBar, NavText, NoAvatar } from "./styled";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authAction } from "../../state";
import { useLocation } from "react-router-dom";
import { Logo, NotificationIcon } from "../../Assets";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NotificationComponent } from "../NotificationComponent";

export const Header: React.FunctionComponent<IHeader.IProps> = (
  props: IHeader.IProps
) => {
  // const {} = props;

  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.auth);

  const { logout } = bindActionCreators(authAction, dispatch);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="pc-view">
        <NavBar style={{ position: "relative" }}>
          <>
            <Logo />
            <NavText
              fontWeight={location.pathname === "/dashboard" ? "600" : "normal"}
              onClick={() => navigate(`/dashboard`)}
            >
              Dashboard
            </NavText>
            <NavText
              onClick={() => navigate(`/students`)}
              fontWeight={
                location.pathname.includes("/student") ? "600" : "normal"
              }
            >
              Students
            </NavText>
            <NavText
              onClick={() => navigate(`/leads`)}
              fontWeight={location.pathname === "/leads" ? "600" : "normal"}
            >
              Leads
            </NavText>
            <NavText
              onClick={() => navigate(`/courses`)}
              fontWeight={location.pathname === "/courses" ? "600" : "normal"}
            >
              Courses
            </NavText>
            <NavText
              onClick={() => navigate(`/whatsapp`)}
              fontWeight={location.pathname === "/whatsapp" ? "600" : "normal"}
            >
              WhatsApp
            </NavText>
            {auth.userDetails.role === "63d2ec57fdc142001c0074e6" ||
            auth.userDetails.role === "63c92057dab279194bab8d8f" ? (
              <NavText
                onClick={() => navigate(`/setting`)}
                fontWeight={location.pathname === "/setting" ? "600" : "normal"}
              >
                Settings
              </NavText>
            ) : null}

            <NavText
              onClick={() => navigate(`/fees`)}
              fontWeight={location.pathname === "/fees" ? "600" : "normal"}
            >
              Fees
            </NavText>
          </>
          {/* <div style={{ position: "relative" }}>
            <SearchBar placeholder="Search Students, Courses" />
            <img
              style={{ position: "absolute", top: 11, left: 8 }}
              src={icon}
              alt="icon"
            />
          </div> */}
          <div
            style={{
              position: "absolute",
              right: 40,display: "flex",
              alignItems: "center",
              gap: "0px", 
            }}
            className="d-flex align-items-center"
          >
            
            <Dropdown>
              <Dropdown.Toggle className="header-avatar-dd">
                {auth.userDetails.avatar ? (
                  <div
                    style={{
                      backgroundImage: `url(${auth.userDetails.avatar})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      border: "1px solid #fff",

                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    }}
                  />
                ) : (
                  <NoAvatar>{auth.userDetails.name[0]}</NoAvatar>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  style={{ borderRadius: 8 }}
                  href={`/profile/${auth.userDetails.id}`}
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NotificationComponent userId={auth.userDetails.id}/>
          </div>
         

        </NavBar>
      </div>
      <div className="mobile-view">
        <Navbar style={{ padding: "15px" }} bg="light" expand="lg" sticky="top">
          <Navbar.Brand href="/">
            <Logo />
            {auth.userDetails.avatar ? (
              <AvatarCon
                style={{
                  backgroundImage: `url(${auth.userDetails.avatar})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            ) : (
              <NoAvatar>{auth.userDetails.name[0]}</NoAvatar>
            )}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              <Nav.Link href="/students">Students</Nav.Link>
              <Nav.Link href="/whatsapp">WhatsApp</Nav.Link>
              <Nav.Link href="/courses">Courses</Nav.Link>
              <Nav.Link href="/leads">Leads</Nav.Link>
              <Nav.Link href="/fees">Fees</Nav.Link>
              {auth.userDetails.role === "63d2ec57fdc142001c0074e6" ||
              auth.userDetails.role === "63c92057dab279194bab8d8f" ? (
                <NavText
                  href="/setting"
                  fontWeight={
                    location.pathname === "/setting" ? "600" : "normal"
                  }
                >
                  Settings
                </NavText>
              ) : null}
              <NavText
                className="d-flex justify-content-center"
                fontWeight={"normal"}
                onClick={() => logout()}
              >
                Logout
              </NavText>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};
