import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket", "polling"], // Allow fallback to polling
      query: { EIO: 4, transport: "websocket" }, // Add required query parameters
      rejectUnauthorized: false, // Ignore SSL errors (for self-signed certificates)
    });

    console.log("Socket initialized:", newSocket); // Debugging

    newSocket.on("connect", () => {
      console.log("Socket connected:", newSocket.id); // Debugging
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected"); // Debugging
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error.message, error.stack); // Debugging
    });

    newSocket.on("error", (error) => {
      console.error("Socket error:", error.message, error.stack); // Debugging
    });

    setSocket(newSocket);

    // Cleanup on unmount
    return () => {
      console.log("Cleaning up socket connection..."); // Debugging
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
