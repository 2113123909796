import * as React from "react";

interface ICommentIconProps {
  bg: string;
}

export const CommentIcon: React.FunctionComponent<ICommentIconProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.bg}
        stroke={props.bg}
        d="M6 14h8v-2H6zm0-3h12V9H6zm0-3h12V6H6zM2 22V4q0-.824.587-1.412A1.93 1.93 0 0 1 4 2h16q.824 0 1.413.587Q22 3.176 22 4v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 18H6zm3.15-6H20V4H4v13.125z"
      ></path>
    </svg>
  );
};
