import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Badge, Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import {
  createDocument,
  getUsersWithoutPagination,
  truncateString,
  updateApplication,
  updateDocument,
  updateStudent,
} from "../../Services/Function";
import { UploadInput, UploadSubtext, UploadText } from "../DocumentPage/style";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";
import { useDispatch } from "react-redux";

type Props = {
  show: any;
  handleClose: any;
  application: any;
  reloadApplication: any;
  studentId: any;
  unconditionalOffer?: any;
  reloadStudents: any;
  documents: any;
  reloadDocuments: any;
  targetPhase: string; // New prop for targetPhase
  studentSelected?: any;
};

// Define the application phases
const applicationPhase = [
  "Initiated",
  "Submitted",
  "Conditional offer",
  "Unconditional offer",
  "Confirmation",
  "FG/BS",
  "CAS Received",
  "Done",
];

export const DragDropModal = (props: Props) => {
  const [users, setUsers] = useState([]);
  const [managedBy, setManagedBy] = useState<any>({});
  const [filePreviewUrls, setFilePreviewUrls] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isImage, setIsImage] = React.useState<boolean[]>([]);
  const [isPdf, setIsPdf] = React.useState<boolean[]>([]);
  const [imageAsFile, setImageAsFile] = React.useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.auth);
  const { handleFileUpload, setUploading } = bindActionCreators(
    dataAction,
    dispatch
  );

  React.useEffect(() => {
    async function fetchData() {
      try {
        const user = await getUsersWithoutPagination();
        setUsers(user);
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, []);

  async function handleImageAsFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const reader = new FileReader();
        reader.onload = async () => {
          setFilePreviewUrls((prevFilePreviewUrls: any) => [
            ...prevFilePreviewUrls,
            reader.result,
          ]);
          setIsImage((prevIsImage: any) => [
            ...prevIsImage,
            selectedFile.type.includes("image"),
          ]);
          setIsPdf((prevIsPdf: any) => [
            ...prevIsPdf,
            selectedFile.type.includes("pdf") ||
              selectedFile.type.includes("doc"),
          ]);

          if (
            selectedFile.type.includes("pdf") ||
            selectedFile.type.includes("image")
          ) {
            setImageAsFile((prev: any) => [...prev, selectedFile]);
          }
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const stages =
        props.application?.portalApplicationStatus?.applicationPhases;
      const targetPhase = props.targetPhase;

      // Define updateDoc and resp variables
      let updateDoc: any[] = [];
      let resp: any;

      // Rule 1: Only one application can be in "Confirmation" or stages ahead of it
      if (
        targetPhase === "Confirmation" ||
        applicationPhase.indexOf(targetPhase) >=
          applicationPhase.indexOf("Confirmation")
      ) {
        if (!props.application.finalChoice && props.studentSelected.onHold) {
          alert(
            "Only one application can be in the Confirmation stage or stages ahead of it."
          );
          props.handleClose(false);
          return;
        }
      }

      // Update application phases logic
      if (stages) {
        stages.forEach((stage: any, index: any) => {
          if (stage.status === targetPhase) {
            stage.phaseState = "AwaitingResponseStudent";
            stage.isCurrent = true;
            stage.isPrevious = false;
          } else if (index < applicationPhase.indexOf(targetPhase)) {
            stage.phaseState = "Completed";
            stage.isCurrent = false;
            stage.isPrevious =
              index === applicationPhase.indexOf(targetPhase) - 1;
          } else {
            stage.phaseState = "Upcoming";
            stage.isCurrent = false;
            stage.isPrevious = false;
          }
        });
      }

      // Handle confirmation stage logic
      const confirmationStage = stages.find(
        (stage: any) => stage.status === "Confirmation"
      );
      console.log(confirmationStage, "fdsad");
      if (confirmationStage) {
        if (
          confirmationStage.phaseState === "AwaitingResponseStudent" ||
          confirmationStage.phaseState === "Completed"
        ) {
          await updateStudent(props.studentId, { onHold: true });
          await updateApplication(props.application._id, { finalChoice: true });
        } else if (
          confirmationStage.phaseState === "Upcoming" &&
          props.application.finalChoice
        ) {
          console.log("innnnn ohold upcomming");

          await updateApplication(props.application._id, {
            finalChoice: false,
          });
          await updateStudent(props.studentId, { onHold: false });
        }
      }

      // Handle file upload if any
      let uploadTask;
      if (imageAsFile.length) {
        uploadTask = handleFileUpload(imageAsFile);
      }

      // Process conditional offer logic
      if (
        stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "Conditional offer" &&
        !props.unconditionalOffer
      ) {
        updateDoc = props.documents.filter(
          (z: any) => z.subType === "Conditional offer"
        );

        if (!updateDoc.length) {
          resp = await createDocument({
            documentType: "Issued Document",
            subType: "Conditional offer",
            tag: {},
            applicationId: props.application._id,
            docState: "Not Uploaded",
            blobInfo: [],
            studentId: props.studentId,
          });
        }
      }

      // Process unconditional offer logic
      if (
        stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "Unconditional offer" ||
        (stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "Conditional offer" &&
          props.unconditionalOffer)
      ) {
        updateDoc = props.documents.filter(
          (z: any) => z.subType === "Unconditional offer"
        );

        const unconditionalOfferStage = stages.find(
          (stage: any) => stage.status === "Unconditional offer"
        );

        if (unconditionalOfferStage) {
          unconditionalOfferStage.phaseState = "AwaitingResponseStudent";
          unconditionalOfferStage.isCurrent = true;
        }

        const submittedStage = stages.find(
          (stage: any) => stage.status === "Submitted"
        );

        if (submittedStage) {
          submittedStage.phaseState = "Completed";
          submittedStage.isCurrent = false;
          submittedStage.isPrevious = false;
        }

        const conditionalOfferStage = stages.find(
          (stage: any) => stage.status === "Conditional offer"
        );

        if (conditionalOfferStage) {
          conditionalOfferStage.phaseState = "Completed";
          conditionalOfferStage.isCurrent = false;
          conditionalOfferStage.isPrevious = true;
        }

        if (!updateDoc.length) {
          resp = await createDocument({
            documentType: "Issued Document",
            subType: "Unconditional offer",
            tag: {},
            applicationId: props.application._id,
            docState: "Not Uploaded",
            blobInfo: [],
            studentId: props.studentId,
          });
        }
      }

      // Process other statuses (Confirmation, FG/BS, CAS Received)
      if (
        stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "Confirmation"
      ) {
        await updateApplication(props.application._id, {
          status: selectedStatus,
        });
      }

      if (
        stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "FG/BS"
      ) {
        updateDoc = props.documents.filter((z: any) => z.subType === "FG/BS");
        if (!updateDoc.length) {
          resp = await createDocument({
            documentType: "Issued Document",
            subType: "FG/BS",
            tag: {},
            applicationId: props.application._id,
            docState: "Not Uploaded",
            blobInfo: [],
            studentId: props.studentId,
          });
        }
      }

      if (
        stages.filter(
          (stage: { phaseState: string }) =>
            stage.phaseState === "AwaitingResponseStudent"
        )[0].status === "CAS Received"
      ) {
        updateDoc = props.documents.filter(
          (z: any) => z.subType === "CAS Received"
        );
        if (!updateDoc.length) {
          resp = await createDocument({
            documentType: "Issued Document",
            subType: "CAS Received",
            applicationId: props.application._id,
            tag: {},
            docState: "Not Uploaded",
            blobInfo: [],
            studentId: props.studentId,
          });
        }
      }

      // Final application update
      await updateApplication(props.application._id, {
        portalApplicationStatus: { applicationPhases: stages },
        phaseChanged: true,
        editor: auth.userDetails,
      });

      // Close modal and reset loading state
      setImageAsFile([]);
      props.reloadApplication();
      props.reloadStudents();
      setLoading(false);
      props.handleClose();

      if (uploadTask) {
        setUploading(updateDoc.length ? updateDoc[0].id : resp.id);
        const downloadURL: any = await uploadTask;
        if (downloadURL?.length) {
          const blobInfo = downloadURL.map((a: any) => ({
            blobUrl: a.url,
            name: a.name,
          }));

          if (updateDoc.length) {
            await updateDocument(updateDoc[0].id, {
              docState:
                updateDoc[0].blobInfo?.length || downloadURL.length
                  ? "Upload"
                  : "Not Uploaded",
              blobInfo: [...updateDoc[0].blobInfo, ...blobInfo],
            });
          } else {
            await updateDocument(resp.id, {
              docState: "Upload",
              blobInfo: blobInfo,
            });
          }

          props.reloadDocuments();
        }
        setUploading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    try {
      setLoading(true);

      const stages =
        props.application?.portalApplicationStatus?.applicationPhases;
      const targetPhase = props.targetPhase;

      // Update application phases logic
      if (stages) {
        stages.forEach((stage: any, index: any) => {
          if (stage.status === targetPhase) {
            stage.phaseState = "AwaitingResponseStudent";
            stage.isCurrent = true;
            stage.isPrevious = false;
          } else if (index < applicationPhase.indexOf(targetPhase)) {
            stage.phaseState = "Completed";
            stage.isCurrent = false;
            stage.isPrevious =
              index === applicationPhase.indexOf(targetPhase) - 1;
          } else {
            stage.phaseState = "Upcoming";
            stage.isCurrent = false;
            stage.isPrevious = false;
          }
        });
      }

      // Update the application with the new phases and other details
      await updateApplication(props.application._id, {
        portalApplicationStatus: { applicationPhases: stages },
        phaseChanged: true,
        editor: auth.userDetails,
      });

      // Close modal and reset loading state
      props.reloadApplication();
      props.reloadStudents();
      setLoading(false);

      props.handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const bg = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];

  console.log(imageAsFile, "dfaf");

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="modal fade"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {props.targetPhase === "Submitted"
              ? "Ready to Apply"
              : props.unconditionalOffer
                ? "Unconditional offer"
                : props.targetPhase}
          </Modal.Title>
        </Modal.Header>

        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ values }) => (
            <Form className="d-flex flex-column" style={{ marginTop: 20 }}>
              <Modal.Body>
                {props.targetPhase === "Submitted" ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="form-input"
                        id="dropdown-custom-components"
                      >
                        {managedBy.name || "Filled By"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dd-custom-menu">
                        {users.map((a: any) => (
                          <Dropdown.Item
                            key={a.id}
                            className="dd-custom-items"
                            onClick={() => setManagedBy(a)}
                          >
                            {a.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : props.targetPhase === "Conditional offer" &&
                  !props.unconditionalOffer ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                        onChange={handleImageAsFile}
                      />
                      <UploadText>Upload Document Conditional</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => (
                          <div
                            style={{ marginRight: 5, marginBottom: 5 }}
                            key={i}
                          >
                            <div
                              onClick={() => {
                                const newData = [...filePreviewUrls];
                                newData.splice(i, 1);
                                setFilePreviewUrls(newData);
                              }}
                              style={{
                                position: "absolute",
                                background: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              X
                            </div>
                            {isImage[i] ? (
                              <img
                                src={filePreviewUrl as string}
                                alt="Preview"
                                style={{
                                  width: 50,
                                  height: 70,
                                  borderRadius: 8,
                                }}
                              />
                            ) : isPdf[i] ? (
                              <p style={{ marginLeft: 25 }}>
                                {truncateString(imageAsFile[i]?.name)}
                              </p>
                            ) : (
                              <p>File type not supported</p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : props.targetPhase === "Unconditional offer" ||
                  (props.targetPhase === "Conditional offer" &&
                    props.unconditionalOffer) ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                        onChange={handleImageAsFile}
                      />
                      <UploadText>Upload Document Unconditional</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => (
                          <div
                            style={{ marginRight: 5, marginBottom: 5 }}
                            key={i}
                          >
                            <div
                              onClick={() => {
                                const newData = [...filePreviewUrls];
                                newData.splice(i, 1);
                                setFilePreviewUrls(newData);
                              }}
                              style={{
                                position: "absolute",
                                background: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              X
                            </div>
                            {isImage[i] ? (
                              <img
                                src={filePreviewUrl as string}
                                alt="Preview"
                                style={{
                                  width: 50,
                                  height: 70,
                                  borderRadius: 8,
                                }}
                              />
                            ) : isPdf[i] ? (
                              <p style={{ marginLeft: 25 }}>
                                {truncateString(imageAsFile[i]?.name)}
                              </p>
                            ) : (
                              <p>File type not supported</p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : props.targetPhase === "Confirmation" ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle"
                        id="dropdown-custom-components"
                        style={{ padding: 0 }}
                      >
                        <div className="d-flex flex-row flex-wrap w-100">
                          {selectedStatus || props.application.status[0] ? (
                            <div
                              className="d-flex flex-column"
                              style={{ position: "relative", marginRight: 12 }}
                            >
                              <Badge
                                pill
                                bg={
                                  selectedStatus ||
                                  props.application.status === "Offer on KCO"
                                    ? "info"
                                    : "success"
                                }
                                style={{ marginBottom: 4, cursor: "pointer" }}
                              >
                                {selectedStatus === ""
                                  ? props.application.status[0]
                                  : selectedStatus}
                              </Badge>
                            </div>
                          ) : (
                            <div
                              className="form-input d-flex align-items-center w-100"
                              style={{ cursor: "pointer" }}
                            >
                              Select Status
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {["Offer on KCO", "KCO Approved", "Status 3"].map(
                          (a: any, key: any) => (
                            <Dropdown.Item key={key}>
                              <Badge
                                pill
                                className="d-flex flwx-column"
                                style={{ marginBottom: 10 }}
                                onClick={() => setSelectedStatus(a)}
                                bg={bg[key]}
                              >
                                {a}
                              </Badge>
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div>Click on Submit to go to next step</div>
                  </>
                ) : props.targetPhase === "FG/BS" ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                        onChange={handleImageAsFile}
                      />
                      <UploadText>Upload Document</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => (
                          <div
                            style={{ marginRight: 5, marginBottom: 5 }}
                            key={i}
                          >
                            <div
                              onClick={() => {
                                const newData = [...filePreviewUrls];
                                newData.splice(i, 1);
                                setFilePreviewUrls(newData);
                              }}
                              style={{
                                position: "absolute",
                                background: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              X
                            </div>
                            {isImage[i] ? (
                              <img
                                src={filePreviewUrl as string}
                                alt="Preview"
                                style={{
                                  width: 50,
                                  height: 70,
                                  borderRadius: 8,
                                }}
                              />
                            ) : isPdf[i] ? (
                              <p style={{ marginLeft: 25 }}>
                                {truncateString(imageAsFile[i]?.name)}
                              </p>
                            ) : (
                              <p>File type not supported</p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : props.targetPhase === "CAS Received" ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                        onChange={handleImageAsFile}
                      />
                      <UploadText>Upload Document</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => (
                          <div
                            style={{ marginRight: 5, marginBottom: 5 }}
                            key={i}
                          >
                            <div
                              onClick={() => {
                                const newData = [...filePreviewUrls];
                                newData.splice(i, 1);
                                setFilePreviewUrls(newData);
                              }}
                              style={{
                                position: "absolute",
                                background: "#fff",
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              X
                            </div>
                            {isImage[i] ? (
                              <img
                                src={filePreviewUrl as string}
                                alt="Preview"
                                style={{
                                  width: 50,
                                  height: 70,
                                  borderRadius: 8,
                                }}
                              />
                            ) : isPdf[i] ? (
                              <p style={{ marginLeft: 25 }}>
                                {truncateString(imageAsFile[i]?.name)}
                              </p>
                            ) : (
                              <p>File type not supported</p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : null}
              </Modal.Body>
              <Modal.Footer className="d-flex flex-row-reverse flex-start w-100">
                <ButtonComponent
                  onClick={props.handleClose}
                  btnType="light"
                  loading={false}
                  label="Close"
                  style={{ width: "max-content", marginLeft: 10 }}
                />
                <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  disabled={
                    loading
                    //  || props.targetPhase === "Confirmation"
                    //   ? false
                    //   : !imageAsFile.length
                  }
                  label="Submit"
                  style={{ width: "max-content" }}
                />
                {/* <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  disabled={loading}
                  label="Skip"
                  onClick={handleSkip}
                  style={{ width: "max-content", marginRight: 10 }}
                /> */}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
