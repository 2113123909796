import React from "react";
import { Modal, Col, Form, Row } from "react-bootstrap";
import { ButtonComponent, MultipleInputComponent } from "../Components";
import { debounce } from "debounce";
import { searchSubjects, searchUniversities } from "../Services/Function";

interface IFilterModalProps {
  show: boolean;
  handleClose: () => void;
  selectedSubject: any[];
  setselectedSubject: any;
  subjects: any[];
  setsubjects: (subjects: any[]) => void;
  courseLevels: any[];
  courselevel: any;
  setcourselevel: (level: any) => void;
  modeOfStudy: string;
  setmodeOfStudy: (mode: string) => void;
  selectedUni: any[];
  setselectedUni: any;
  universities: any[];
  setuniversities: any;
  filterCourses: (
    subject: any,
    attendanceType: any,
    courseLevel: any,
    selectedUni: any,
    city: any,
    courseDurationValues: any
  ) => Promise<void>;
  selectedCity: string;
  setselectedCity: (city: string) => void;
  courseDuration: string;
  setcourseDuration: (duration: string) => void;
}

export const FilterModal: React.FC<IFilterModalProps> = ({
  show,
  handleClose,
  selectedSubject,
  setselectedSubject,
  subjects,
  setsubjects,
  courseLevels,
  courselevel,
  setcourselevel,
  modeOfStudy,
  setmodeOfStudy,
  selectedUni,
  setselectedUni,
  universities,
  setuniversities,
  filterCourses,
  selectedCity,
  setselectedCity,
  courseDuration,
  setcourseDuration,
}) => {
  const [searchText, setsearchText] = React.useState("");
  const [searchUniText, setsearchUniText] = React.useState("");
  const [errorMessage, seterrorMessage] = React.useState("");

  const searchSubect = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setsubjects([]);
      } else {
        const res = await searchSubjects({
          text: e.target.value,
        });
        setsubjects(res?.results);
      }
    },
    500
  );

  const searchUniversity = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setuniversities([]);
      } else {
        const res = await searchUniversities({
          text: e.target.value,
        });
        setuniversities(res?.results);
      }
    },
    500
  );

  const handleApplyFilters = async () => {
    await filterCourses(
      selectedSubject,
      modeOfStudy,
      courselevel?.value || "",
      selectedUni,
      selectedCity,
      courseDuration
    );
    handleClose();
  };

  return (
    <Modal className="modal side-modal fade" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Sort and filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Col md={12}>
            <Form.Group>
              <Form.Label
                style={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Subjects
              </Form.Label>
              <MultipleInputComponent
                selectedUniversity={selectedSubject}
                setselectedUniversity={setselectedSubject}
                setsearchText={setsearchText}
                arabic={false}
                coursePage={false}
                autoComplete={"off"}
                multiSelectClass="subject-multiselect-input"
                className="subject-input"
                universities={subjects}
                searchText={searchText}
                searchUniversity={(e: any) => searchSubect(e)}
                setuniversities={setsubjects}
                seterrorMessage={seterrorMessage}
                error={
                  errorMessage ? (
                    <div style={{ marginBottom: 5 }}>{errorMessage}</div>
                  ) : null
                }
              />
            </Form.Group>
          </Col>

          <Col md={12} style={{ marginBottom: 24 }}>
            <Form.Group>
              <Form.Label
                style={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Course Level
              </Form.Label>
              <Form.Select
                value={courselevel?.value || ""}
                onChange={(e) => {
                  const level = [
                    { name: "Foundation", value: "Foundation" },
                    { name: "Bachelor's", value: "Undergraduate" },
                    { name: "Master's", value: "Postgraduate" },
                    { name: "Phd", value: "Doctorate" },
                  ].find((l) => l.value === e.target.value);
                  setcourselevel(level);
                }}
              >
                <option value="">Select Level</option>
                {[
                  { name: "Foundation", value: "Foundation" },
                  { name: "Bachelor's", value: "Undergraduate" },
                  { name: "Master's", value: "Postgraduate" },
                  { name: "Phd", value: "Doctorate" },
                ].map((a: any, key: any) => (
                  <option key={key} value={a.value}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={12} style={{ marginBottom: 24 }}>
            <Form.Group>
              <Form.Label
                style={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Mode of Study
              </Form.Label>
              <Form.Select
                value={modeOfStudy}
                onChange={(e) => setmodeOfStudy(e.target.value)}
              >
                <option value="FullTime">Full Time</option>
                <option value="DistanceLearning">Distance Learning</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group>
              <Form.Label
                style={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                University
              </Form.Label>
              <MultipleInputComponent
                selectedUniversity={selectedUni}
                setselectedUniversity={setselectedUni}
                setsearchText={setsearchUniText}
                autoComplete={"off"}
                arabic={false}
                coursePage={false}
                multiSelectClass="subject-multiselect-input"
                className="subject-input"
                universities={universities}
                searchText={searchUniText}
                searchUniversity={(e: any) => searchUniversity(e)}
                setuniversities={setuniversities}
                seterrorMessage={seterrorMessage}
                error={
                  errorMessage ? (
                    <div style={{ marginBottom: 5 }}>{errorMessage}</div>
                  ) : null
                }
              />
            </Form.Group>
          </Col>
        </>
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          label="Close"
          onClick={handleClose}
          btnType="light"
          style={{ marginRight: 10 }}
          loading={false}
        />
        <ButtonComponent
          label="Apply Filters"
          onClick={handleApplyFilters}
          btnType="dark"
          loading={false}
        />
      </Modal.Footer>
    </Modal>
  );
};
