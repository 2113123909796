/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { ButtonComponent } from "../ButtonComponent";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import { FormInput } from "../FormInput";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { DropdownComponent } from "../DropdownComponent";
import {
  AddContactsText,
  EditSectionCard,
  EditSectionHeader,
  EditSectionLabel,
  EditSectionText,
  EditSectionValue,
  EditText,
  Label,
  Required,
  ToggleText,
} from "./styled";
import PhoneInput from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";

import { ToggleButton } from "../ToggleButton";
import { CountryInputComponent } from "../CountryInputComponent";
import { debounce } from "lodash";
import {
  searchCountries,
  searchSubjects,
  updateStudent,
} from "../../Services/Function";
import { Values } from "./about";
import moment from "moment";
import { MultipleInputComponent } from "../MultipleInputComponent";
import { DateComponent } from "../DateComponent";
import { TextArea } from "../TextArea";

interface IAboutPageProps {
  student: any;
  courseLevels: Array<object>;
  reloadStudents: Function;
}

export const AboutPage: React.FunctionComponent<IAboutPageProps> = (props) => {
  const { student, courseLevels, reloadStudents } = props;

  const [phoneNo, setphoneNo] = useState(student?.phoneNo);
  const [emergencyContactNo, setemergencyContactNo] = useState("");
  const [selectedEmgContact, setselectedEmgContact] = useState<any>("");
  const [editEmergencyCon, seteditEmergencyCon] = useState(false);
  const [edit, setedit] = useState(false);
  const [backlog, setbacklog] = useState(student?.backlogs);
  const [educationGap, seteducationGap] = useState(student?.educationGaps);
  const [selectedCountry, setselectedCountry] = useState(student?.nationality);
  const [countries, setcountries] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [dob, setdob] = useState(student.dob || "");
  const [searchText1, setsearchText1] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [customValue, setCustomValue] = useState("");

  // const [scholarRequired, setscholarRequired] = useState(
  //   student.preference?.needScholarship || false
  // );
  const [emergencyContact, setemergencyContact] = useState(
    student.emergencyContact || false
  );
  // const [placementRequired, setplacementRequired] = useState(
  //   student.preference?.needPlacement || false
  // );
  const [residence, setresidence] = useState([]);
  const [selectedresidence, setselectedresidence] = useState(
    student?.residence || ""
  );
  const [loading, setloading] = useState(false);
  const [selectedSubject, setselectedSubject] = useState(
    student.preference?.courseSubjectIds || []
  );
  const [subjects, setsubjects] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [visaRejected, setvisaRejected] = useState(
    student.visaRejected || false
  );
  const [addEmergencyCon, setaddEmergencyCon] = useState(false);

  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setcountries(res?.results);
    }
  }, 500);

  const searchresidence = debounce(async (e: any) => {
    if (!e.target.value) {
      setresidence([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setresidence(res?.results);
    }
  }, 500);

  const searchSub = debounce(async (e: any) => {
    if (!e.target.value) {
      setsubjects([]);
    } else {
      const res = await searchSubjects({
        text: e.target.value,
      });
      setsubjects(res?.results);
    }
  }, 500);

  const gender = ["Male", "Female", "Others", "Not Willing To Disclose"];
  const ieltsTaken = ["Yes", "No", "Waiting for results"];
  const destinations = [
    { en: "United Kingdom", ar: "بريطانيا" },
    { en: "Other", ar: "غيرها" },
  ];
  const currencies = ["AED", "SAR", "KWD"];
  const sorceOfFunding = ["Sponsored", "Self Funded"];
  const kcoCheckList = ["Account created", "Account activated"];
  const today = new Date();

  let year = moment(today).format("YYYY");
  const excludedCountries = ["IL"]; // Countries to exclude

  const filteredCountries = getCountries().filter(
    (country) => !excludedCountries.includes(country)
  );

  const handleSelectChange = (e: any) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value !== "Other") {
      setCustomValue(""); // Clear custom input when selecting predefined options
    }
  };

  return (
    <div>
      {!Object.keys(student).length ? (
        "Loading.."
      ) : !edit ? (
        <div>
          <div className="d-flex w-100 justify-content-between">
            {/* {student.preference?.kcoStatus &&
            student.preference?.fundingSource ? ( */}
            <EditSectionText>
              {student.preference?.kcoStatus
                ? student.preference?.kcoStatus
                : null}{" "}
              {student.preference?.fundingSource
                ? ` | ${student.preference?.fundingSource} -${student.preference?.sponsoredBy}`
                : null}
            </EditSectionText>
            {/* ) : null} */}
            <ButtonComponent
              style={{ paddding: "9px 13px", width: "max-content" }}
              label={`Edit`}
              onClick={() => setedit(true)}
              loading={false}
              btnType={"dark"}
              icon={
                <svg
                  style={{ marginRight: 12 }}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                    fill="#E8EAED"
                  />
                </svg>
              }
            />
          </div>
          <div
            style={{ margin: "32px 0 40px 0" }}
            className="d-flex flex-column"
          >
            <EditSectionHeader>👤 Information</EditSectionHeader>
            <div style={{ marginTop: 15 }} className="row">
              <Col md={3}>
                <div className="d-flex flex-column">
                  <EditSectionLabel>Country of study</EditSectionLabel>
                  <EditSectionValue>
                    {student.preference?.studyDestinations}
                  </EditSectionValue>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex flex-column">
                  <EditSectionLabel>Field of Study</EditSectionLabel>
                  <EditSectionValue>
                    {student.preference?.courseSubjectIds
                      .map((a: any) => a.name)
                      .join(", ")}
                  </EditSectionValue>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex flex-column">
                  <EditSectionLabel>Start date</EditSectionLabel>
                  <EditSectionValue>
                    {student.preference?.intakeMonth}{" "}
                    {student.preference?.intakeYear}
                  </EditSectionValue>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex flex-column">
                  <EditSectionLabel>Residential Address</EditSectionLabel>
                  <EditSectionValue>{student.address}</EditSectionValue>
                </div>
              </Col>
            </div>
          </div>
          <div className="row">
            <Col md={7}>
              <EditSectionHeader>🔐 Student Credentials</EditSectionHeader>

              <EditSectionCard style={{ margin: "12px 0 12px 0" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <EditSectionValue>UCAS</EditSectionValue>
                    <EditSectionLabel>mohammed.kw@gmail.com</EditSectionLabel>
                  </div>
                  <EditSectionValue style={{ fontWeight: 500 }}>
                    JSxhsoSSja
                  </EditSectionValue>
                </div>
              </EditSectionCard>
            </Col>
            {emergencyContact.length ? (
              <Col md={5}>
                <EditSectionHeader>📞 Emergency contacts</EditSectionHeader>

                {emergencyContact.map((contact: any) => (
                  <EditSectionCard style={{ margin: "12px 0 12px 0" }}>
                    <div className="d-flex flex-column">
                      <EditSectionValue>
                        {contact.relation}: {contact.firstName}{" "}
                        {contact.lastName}
                      </EditSectionValue>
                      <EditSectionLabel>
                        {contact.emergencyContactNo} • {contact.email}
                      </EditSectionLabel>
                    </div>
                  </EditSectionCard>
                ))}
              </Col>
            ) : null}
          </div>
          {student.importantComment ? (
            <div style={{ margin: "28px 0 0px 0" }}>
              <EditSectionCard>
                <div className="d-flex flex-column">
                  <EditSectionHeader>🎓 Notes</EditSectionHeader>
                  <EditSectionValue style={{ fontWeight: 500, marginTop: 12 }}>
                    {student.importantComment}
                  </EditSectionValue>
                </div>
              </EditSectionCard>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example">
            <Tab eventKey="basic" title="Basic Details">
              <Formik
                initialValues={{
                  firstName: student.firstName,
                  lastName: student.lastName,
                  middleName: student.middleName,
                  email: student.email,
                  gender: student.gender,
                  importantComment: student.importantComment,
                  address: student.address,
                }}
                onSubmit={async (
                  values: Values,
                  { setSubmitting }: FormikHelpers<Values>
                ) => {
                  setloading(true);
                  await updateStudent(student.id, {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    gender: values.gender,
                    nationality: selectedCountry,
                    residence: selectedresidence,
                    importantComment: values.importantComment,
                    middleName: values.middleName,
                    phoneNo: phoneNo,
                    dob: dob,
                    address: values.address,
                  });
                  await reloadStudents();
                  setedit(false);
                  setloading(false);
                }}
              >
                <Form style={{ width: "70%" }} className="d-flex flex-column">
                  <Row>
                    <Col md={4} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> First Name
                      </Label>
                      <FormInput
                        id="firstName"
                        style={{ borderRadius: 3, marginBottom: 0 }}
                        name="firstName"
                        placeholder=""
                        type="text"
                        //   label=""
                      />
                    </Col>
                    <Col md={4} style={{ marginBottom: 32 }}>
                      <Label>Middle Name</Label>
                      <FormInput
                        id="middleName"
                        style={{ borderRadius: 3, marginBottom: 0 }}
                        name="middleName"
                        placeholder=""
                        type="text"
                        //   label=""
                      />
                    </Col>

                    <Col md={4} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Last Name
                      </Label>
                      <FormInput
                        id="lastName"
                        style={{ borderRadius: 3, marginBottom: 0 }}
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Nationality
                      </Label>
                      <CountryInputComponent
                        selectedCountry={selectedCountry}
                        autoComplete="new-password"
                        setsearchText={setsearchText1}
                        setselectedCountry={setselectedCountry}
                        country={true}
                        searchCountry={searchCountry}
                        countries={countries}
                        setcountries={setcountries}
                        searchText={searchText1}
                        arabic={false}
                        placeholder={""}
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Country
                      </Label>
                      <CountryInputComponent
                        selectedCountry={selectedresidence}
                        arabic={false}
                        autoComplete="new-password"
                        setsearchText={setsearchText}
                        setselectedCountry={setselectedresidence}
                        country={false}
                        searchCountry={searchresidence}
                        countries={residence}
                        setcountries={setresidence}
                        searchText={searchText}
                        placeholder={""}
                      />
                    </Col>

                    {/* <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> City of Residence
                      </Label>
                      <DropdownComponent
                        children={[]}
                        onClick={() => {}}
                        className={""}
                        style={undefined}
                        placeholder="City of Residence"
                      />
                    </Col> */}

                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Gender
                      </Label>
                      <Field className="form-input" as="select" name="gender">
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {gender.map((a) => (
                          <option value={a}>{a}</option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Label>
                        <Required>*</Required> Phone Number
                      </Label>
                      <PhoneInput
                        international
                        defaultCountry="KW"
                        countries={filteredCountries}
                        value={phoneNo || student.phoneNo}
                        onChange={(e: any) => setphoneNo(e)}
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <DateComponent
                        monthLabel={""}
                        yearLabel={"DOB"}
                        date={dob}
                        dateFromChild={(data: React.SetStateAction<string>) => {
                          setdob(data);
                        }}
                        label={""}
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Primary Email
                      </Label>
                      <FormInput
                        id="email"
                        style={{ borderRadius: 3, marginBottom: 0 }}
                        name="email"
                        placeholder="Primary Email"
                        type="email"
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Important Comment
                      </Label>
                      <TextArea
                        id={""}
                        style={{ width: "100% !important" }}
                        name={"importantComment"}
                        placeholder={""}
                        type={"text"}
                        rows={"1"}
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Address
                      </Label>
                      <TextArea
                        id={""}
                        name={"address"}
                        style={{ width: "100% !important" }}
                        placeholder={"Address"}
                        type={"text"}
                        rows={"1"}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row">
                    <ButtonComponent
                      style={{ borderRadius: 20, width: "max-content" }}
                      btnType="dark"
                      loading={loading}
                      label={loading ? "Loading..." : "SAVE"}
                    />
                    <ButtonComponent
                      style={{
                        borderRadius: 20,
                        width: "max-content",
                        marginLeft: 12,
                      }}
                      onClick={() => {}}
                      btnType="light"
                      loading={false}
                      label="EXIT"
                    />
                  </div>
                </Form>
              </Formik>
            </Tab>
            <Tab eventKey="preferences" title="Study Preferences">
              <Formik
                initialValues={{
                  destination: student.preference?.studyDestinations,
                  courseLevel: student.preference?.courseLevel,
                  intakeYear: student.preference?.intakeYear,
                  intakeMonth: student.preference?.intakeMonth,
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  const courseIds = await selectedSubject.map((a: any) => {
                    return { slug: a.slug || a.key, name: a.name };
                  });

                  setloading(true);
                  await updateStudent(student.id, {
                    preference: {
                      studyDestinations: values.destination,
                      courseLevel: values.courseLevel,
                      courseSubjectIds: courseIds,
                      intakeYear: values.intakeYear,
                      intakeMonth: values.intakeMonth,
                      currency: student?.preference?.currency,
                      fundingSource: student?.preference?.fundingSource,
                      budget: student?.preference?.budget,
                      needPlacement: student?.preference?.needPlacement,
                      needScholarship: student?.preference?.needScholarship,
                    },
                  });
                  await reloadStudents();
                  setedit(false);
                  setloading(false);
                }}
              >
                <Form style={{ width: "70%" }} className="d-flex flex-column">
                  <Row>
                    <Col md={6}>
                      <Label>
                        <Required>*</Required> Study Destination
                      </Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="destination"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {destinations
                          // .filter(
                          //   (z: any) =>
                          //     z.en === student.preference?.studyDestinations
                          // )
                          .map((a, key) => (
                            <option key={key} value={a.en}>
                              {a.en}
                            </option>
                          ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Label>
                        <Required>*</Required> Course Level
                      </Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="courseLevel"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>

                        {courseLevels?.map((a: any, key: any) => (
                          <>
                            <option key={key} value={a.id}>
                              {a.en_name}
                            </option>
                          </>
                        ))}
                      </Field>
                    </Col>
                    <Col md={12} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Subjects
                      </Label>

                      <MultipleInputComponent
                        selectedUniversity={selectedSubject}
                        setselectedUniversity={setselectedSubject}
                        setsearchText={setsearchText}
                        arabic={false}
                        universities={subjects}
                        searchText={searchText}
                        searchUniversity={(e: any) => searchSub(e)}
                        setuniversities={setsubjects}
                        seterrorMessage={seterrorMessage}
                        error={
                          errorMessage ? (
                            <div style={{ marginBottom: 5 }}>
                              {errorMessage}
                            </div>
                          ) : null
                        }
                      />
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Intake Year
                      </Label>
                      <Field
                        className="form-input"
                        as="select"
                        name="intakeYear"
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value={year}>{year}</option>
                        <option value={parseInt(year) + 1}>
                          {parseInt(year) + 1}
                        </option>
                        <option value={parseInt(year) + 2}>
                          {parseInt(year) + 2}
                        </option>
                        <option value={parseInt(year) + 3}>
                          {parseInt(year) + 3}
                        </option>
                      </Field>
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Intake Month
                      </Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="intakeMonth"
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {moment.months().map((a, key) => (
                          <option key={key} value={a}>
                            {a}
                          </option>
                        ))}
                      </Field>
                    </Col>
                  </Row>
                  <div className="d-flex flex-row">
                    <ButtonComponent
                      style={{ borderRadius: 20, width: "max-content" }}
                      btnType="dark"
                      loading={loading}
                      label={loading ? "Loading..." : "SAVE"}
                    />
                    <ButtonComponent
                      style={{
                        borderRadius: 20,
                        width: "max-content",
                        marginLeft: 12,
                      }}
                      onClick={() => {}}
                      btnType="light"
                      loading={false}
                      label="EXIT"
                    />
                  </div>
                </Form>
              </Formik>
            </Tab>
            <Tab eventKey="level" title="Educational Level">
              <Formik
                initialValues={{
                  testTaken: student.testTaken,
                  score: student.testScore,
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  setloading(true);
                  await updateStudent(student.id, {
                    testTaken: values.testTaken,
                    testScore: values.score,
                    backlogs: backlog,
                    educationGaps: educationGap,
                  });
                  setedit(false);
                  setloading(false);
                }}
              >
                {({ values }) => (
                  <Form style={{ width: "70%" }} className="d-flex flex-column">
                    <Row>
                      <Col md={6} style={{ marginBottom: 32 }}>
                        <Label>
                          <Required>*</Required> Test Taken
                        </Label>
                        <Field
                          className="form-input"
                          as="select"
                          name="testTaken"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>

                          {ieltsTaken?.map((a: any, key: any) => (
                            <option key={key} value={a}>
                              {a}
                            </option>
                          ))}
                        </Field>
                      </Col>

                      {values.testTaken === "Yes" ? (
                        <>
                          <Col md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              <Required>*</Required> Type of English Test
                            </Label>
                            <Field
                              className="form-input"
                              as="select"
                              name="typeOfTest"
                            >
                              <option value="" disabled selected>
                                Select
                              </option>

                              {[
                                "IELTS UKVI Academic",
                                "IELTS Academic",
                                "TOEFL",
                                "Duolingo",
                                "Skills of English",
                                "LanguageCert",
                              ]?.map((a: any, key: any) => (
                                <option key={key} value={a}>
                                  {a}
                                </option>
                              ))}
                            </Field>
                          </Col>
                          <Col md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              {" "}
                              <Required>*</Required> Score
                            </Label>
                            <FormInput
                              id="score"
                              style={{ borderRadius: 3, marginBottom: 0 }}
                              name="score"
                              placeholder="Score"
                              type="text"
                            />
                          </Col>
                        </>
                      ) : null}

                      <Col md={12}>
                        <div className="d-flex flex-row align-items-center">
                          <ToggleButton
                            label="Backlogs"
                            onChange={() => setbacklog(!backlog)}
                            checked={backlog}
                            style={{ marginLeft: 0 }}
                          />
                          <ToggleText>Backlogs</ToggleText>
                        </div>
                      </Col>
                      <Col md={12} style={{ marginTop: 32, marginBottom: 32 }}>
                        <div className="d-flex flex-row align-items-center">
                          <ToggleButton
                            label="Education Gaps"
                            onChange={() => seteducationGap(!educationGap)}
                            checked={educationGap}
                            style={{ marginLeft: 0 }}
                          />
                          <ToggleText>Education Gaps</ToggleText>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-row">
                      <ButtonComponent
                        style={{ borderRadius: 20, width: "max-content" }}
                        btnType="dark"
                        loading={loading}
                        label={loading ? "Loading..." : "SAVE"}
                      />
                      <ButtonComponent
                        style={{
                          borderRadius: 20,
                          width: "max-content",
                          marginLeft: 12,
                        }}
                        onClick={() => {}}
                        btnType="light"
                        loading={false}
                        label="EXIT"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </Tab>
            <Tab eventKey="funding" title="Funding Details">
              <Formik
                initialValues={{
                  kcoStatus: student.preference?.kcoStatus,
                  sponsoredBy: student.preference?.sponsoredBy,
                  source: student.preference?.fundingSource,
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  setloading(true);
                  await updateStudent(student.id, {
                    preference: {
                      kcoStatus: values.kcoStatus,
                      fundingSource: values.source,
                      sponsoredBy:
                        selectedOption === "Other"
                          ? customValue
                          : selectedOption,
                      // needPlacement: placementRequired,
                      // needScholarship: scholarRequired,
                      studyDestinations: student.preference?.studyDestinations,
                      courseLevel: student.preference?.courseLevel,
                      courseSubjectIds: student.preference?.courseSubjectIds,
                      intakeYear: student.preference?.intakeYear,
                      intakeMonth: student.preference?.intakeMonth,
                    },
                  });
                  await reloadStudents();
                  setedit(false);
                  setloading(false);
                }}
              >
                {({ values }) => (
                  <Form style={{ width: "70%" }} className="d-flex flex-column">
                    <Row>
                      <Col md={6} style={{ marginBottom: 32 }}>
                        <Label>
                          <Required>*</Required> Source of Funding
                        </Label>
                        <Field className="form-input" as="select" name="source">
                          <option value="" disabled selected>
                            Select
                          </option>
                          {sorceOfFunding.map((a, key) => (
                            <option key={key} value={a}>
                              {a}
                            </option>
                          ))}
                        </Field>
                      </Col>
                      {values.source === "Sponsored" ? (
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Sponsored By
                          </Label>
                          <Field
                            className="form-input"
                            as="select"
                            name="sponsoredBy"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {[
                              "MOHE Kuwait",
                              "The Custodian of the Two Holy Mosques Scholarship Program",
                            ].map((a, key) => (
                              <option key={key} value={a}>
                                {a}
                              </option>
                            ))}
                            <option value="Other">Other</option>
                          </Field>

                          {/* Conditionally render input field if 'Other' is selected */}
                          {selectedOption === "Other" && (
                            <Field
                              className="form-input"
                              name="customSponsoredBy"
                              placeholder="Enter custom sponsor"
                              value={customValue}
                              onChange={(e: any) =>
                                setCustomValue(e.target.value)
                              }
                            />
                          )}
                        </Col>
                      ) : null}

                      <Col md={6} style={{ marginBottom: 32 }}>
                        <Label>
                          <Required>*</Required>KCO Check List
                        </Label>
                        <Field
                          className="form-input"
                          as="select"
                          name="kcoStatus"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {kcoCheckList.map((a, key) => (
                            <option key={key} value={a}>
                              {a}
                            </option>
                          ))}
                        </Field>
                      </Col>

                      {/* <Col md={12}>
                        <div className="d-flex flex-row align-items-center">
                          <ToggleButton
                            label="scholarRequired"
                            onChange={() =>
                              setscholarRequired(!scholarRequired)
                            }
                            checked={scholarRequired}
                            style={{ marginLeft: 0 }}
                          />
                          <ToggleText>Scholarship Required</ToggleText>
                        </div>
                      </Col>
                      <Col md={12} style={{ marginTop: 32 }}>
                        <div className="d-flex flex-row align-items-center">
                          <ToggleButton
                            label="placementRequired"
                            onChange={() =>
                              setplacementRequired(!placementRequired)
                            }
                            checked={placementRequired}
                            style={{ marginLeft: 0 }}
                          />
                          <ToggleText>Placement Required</ToggleText>
                        </div>
                      </Col> */}
                    </Row>
                    <div style={{ marginTop: 32 }} className="d-flex flex-row">
                      <ButtonComponent
                        style={{ borderRadius: 20, width: "max-content" }}
                        btnType="dark"
                        loading={loading}
                        label={loading ? "Loading..." : "SAVE"}
                      />
                      <ButtonComponent
                        style={{
                          borderRadius: 20,
                          width: "max-content",
                          marginLeft: 12,
                        }}
                        onClick={() => {}}
                        btnType="light"
                        loading={false}
                        label="EXIT"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </Tab>
            <Tab eventKey="travelDetails" title="Travel Details">
              <Formik
                initialValues={{
                  passportStatus: student.passportStatus,
                  passportNo: student.passportNo,
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  setloading(true);
                  await updateStudent(student.id, {
                    passportStatus: values.passportStatus,
                    passportNo: values.passportNo,
                    visaRejected: visaRejected,
                  });
                  setloading(false);
                  reloadStudents();
                  setedit(false);
                }}
              >
                <Form style={{ width: "70%" }} className="d-flex flex-column">
                  <Row>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Do you have Passport?
                      </Label>
                      <Field
                        className="form-input"
                        as="select"
                        name="passportStatus"
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value={"yes"}>{"Has Passport"}</option>
                        <option value={"no"}>{"Does Not Have Passport"}</option>
                        <option value={"applied"}>
                          {"Applied For Passport"}
                        </option>
                      </Field>
                    </Col>
                    <Col md={6} style={{ marginBottom: 32 }}>
                      <Label>
                        <Required>*</Required> Passport Number
                      </Label>
                      <FormInput
                        id="passportNo"
                        style={{ borderRadius: 3, marginBottom: 0 }}
                        name="passportNo"
                        placeholder="Passport Number"
                        type="text"
                      />
                    </Col>
                    <Col md={12}>
                      <div className="d-flex flex-row align-items-center">
                        <ToggleButton
                          label="rejected"
                          onChange={() => setvisaRejected(!visaRejected)}
                          checked={visaRejected}
                          style={{ marginLeft: 0 }}
                        />
                        <ToggleText>Visa Rejected</ToggleText>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ marginTop: 32 }} className="d-flex flex-row">
                    <ButtonComponent
                      style={{ borderRadius: 20, width: "max-content" }}
                      btnType="dark"
                      loading={loading}
                      label={loading ? "Loading..." : "SAVE"}
                    />
                    <ButtonComponent
                      style={{
                        borderRadius: 20,
                        width: "max-content",
                        marginLeft: 12,
                      }}
                      onClick={() => {}}
                      btnType="light"
                      loading={false}
                      label="EXIT"
                    />
                  </div>
                </Form>
              </Formik>
            </Tab>
            <Tab eventKey="contact" title="Emergency Contacts">
              <Formik
                initialValues={{}}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  console.log("first");
                }}
              >
                <Form style={{ width: "70%" }}>
                  {emergencyContact.length && !editEmergencyCon
                    ? emergencyContact.map((a: any, key: any) => (
                        <Row
                          style={{
                            marginBottom: 30,
                            paddingBottom: 20,
                            borderBottom: "1px solid #eaecf0",
                          }}
                        >
                          <Col key={key} md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              <Required>*</Required> First Name
                            </Label>
                            <FormInput
                              id={`firstName${key}`}
                              style={{ borderRadius: 3, marginBottom: 0 }}
                              name={`firstName${key}`}
                              placeholder={a.firstName}
                              type="text"
                              //   label=""
                            />
                          </Col>
                          <Col md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              <Required>*</Required> Last Name
                            </Label>
                            <FormInput
                              id={`lastName${key}`}
                              style={{ borderRadius: 3, marginBottom: 0 }}
                              name={`lastName${key}`}
                              placeholder={a.lastName}
                              type="text"
                            />
                          </Col>

                          <Col md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              <Required>*</Required> Relationship
                            </Label>
                            <FormInput
                              id={`relation${key}`}
                              style={{ borderRadius: 3, marginBottom: 0 }}
                              name={`relation${key}`}
                              placeholder={a.relation}
                              type="text"
                              //   label=""
                            />
                          </Col>
                          <Col md={6} style={{ marginBottom: 32 }}>
                            <Label>
                              <Required>*</Required> Email Address
                            </Label>
                            <FormInput
                              id={`email${key}`}
                              style={{ borderRadius: 3, marginBottom: 0 }}
                              name={`email${key}`}
                              placeholder={a.email}
                              type="email"
                            />
                          </Col>
                          <Col md={6}>
                            <Label>
                              <Required>*</Required> Phone Number
                            </Label>
                            <PhoneInput
                              international
                              defaultCountry="KW"
                              countries={filteredCountries}
                              value={a.emergencyContactNo}
                              onChange={(e: any) => setemergencyContactNo(e)}
                            />
                          </Col>
                          <div className="d-flex flex-row">
                            <EditText
                              onClick={() => {
                                seteditEmergencyCon(true);
                                setselectedEmgContact(a);
                                setemergencyContactNo(a.emergencyContactNo);
                              }}
                            >
                              Edit
                            </EditText>

                            <EditText
                              style={{ color: "red", marginLeft: 10 }}
                              onClick={async () => {
                                setloading(true);
                                let data = emergencyContact.filter(
                                  (z: any) => z._id !== a._id
                                );
                                await updateStudent(student.id, {
                                  emergencyContact: [...data],
                                });
                                setemergencyContact([...data]);
                                await reloadStudents();
                                setloading(false);
                              }}
                            >
                              Delete
                            </EditText>
                          </div>
                        </Row>
                      ))
                    : null}
                </Form>
              </Formik>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  relation: "",
                  email: "",
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  setloading(true);
                  const data = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    relation: values.relation,
                    email: values.email,
                    emergencyContactNo,
                  };

                  await updateStudent(student.id, {
                    emergencyContact: [...emergencyContact, data],
                  });
                  setaddEmergencyCon(false);
                  setemergencyContact([...emergencyContact, data]);
                  values.firstName = "";
                  values.lastName = "";
                  values.relation = "";
                  values.email = "";
                  setemergencyContactNo("");

                  await reloadStudents();
                  setedit(false);
                  setloading(false);
                }}
              >
                {({ values }) => (
                  <Form style={{ width: "70%" }} className="d-flex flex-column">
                    {" "}
                    {!emergencyContact.length || addEmergencyCon ? (
                      <Row
                        style={{
                          marginBottom: 30,
                          paddingBottom: 20,
                          borderBottom: "1px solid #eaecf0",
                        }}
                      >
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> First Name
                          </Label>
                          <FormInput
                            id={`firstName`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`firstName`}
                            type="text"
                            //   label=""
                          />
                        </Col>
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Last Name
                          </Label>
                          <FormInput
                            id={`lastName`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`lastName`}
                            type="text"
                          />
                        </Col>

                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Relationship
                          </Label>
                          <FormInput
                            id={`relation`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`relation`}
                            type="text"
                            //   label=""
                          />
                        </Col>
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Email Address
                          </Label>
                          <FormInput
                            id={`email`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`email`}
                            type="email"
                          />
                        </Col>
                        <Col md={6}>
                          <Label>
                            <Required>*</Required> Phone Number
                          </Label>
                          <PhoneInput
                            international
                            defaultCountry="KW"
                            countries={filteredCountries}
                            value={emergencyContactNo}
                            onChange={(e: any) => setemergencyContactNo(e)}
                          />
                        </Col>
                        <div
                          style={{ marginTop: 32 }}
                          className="d-flex flex-row"
                        >
                          <ButtonComponent
                            style={{ borderRadius: 20, width: "max-content" }}
                            btnType="dark"
                            loading={loading}
                            label={loading ? "Loading..." : "SAVE"}
                          />
                          <ButtonComponent
                            style={{
                              borderRadius: 20,
                              width: "max-content",
                              marginLeft: 12,
                            }}
                            btnType="light"
                            onClick={() => setaddEmergencyCon(false)}
                            loading={false}
                            label="EXIT"
                          />
                        </div>
                      </Row>
                    ) : null}
                    {editEmergencyCon ? null : (
                      <Col md={12}>
                        <div className="d-flex flex-row align-items-center">
                          <AddContactsText
                            onClick={() => {
                              setaddEmergencyCon(true);
                            }}
                          >
                            + Add Another Contact
                          </AddContactsText>
                        </div>
                      </Col>
                    )}
                  </Form>
                )}
              </Formik>
              {editEmergencyCon ? (
                <Formik
                  initialValues={{
                    firstName: selectedEmgContact.firstName,
                    lastName: selectedEmgContact.lastName,
                    relation: selectedEmgContact.relation,
                    email: selectedEmgContact.email,
                  }}
                  onSubmit={async (
                    values: any,
                    { setSubmitting }: FormikHelpers<any>
                  ) => {
                    setloading(true);

                    for (let i = 0; i < emergencyContact.length; i++) {
                      if (emergencyContact[i]._id === selectedEmgContact._id) {
                        // Update the object properties for the matching ID
                        emergencyContact[i].firstName = values.firstName;
                        emergencyContact[i].lastName = values.lastName;
                        emergencyContact[i].email = values.email;
                        emergencyContact[i].relation = values.relation;
                        emergencyContact[i].emergencyContactNo =
                          emergencyContactNo;
                        break; // Exit the loop after updating the first matching ID
                      }
                    }

                    await updateStudent(student.id, {
                      emergencyContact: emergencyContact,
                    });
                    seteditEmergencyCon(false);
                    await reloadStudents();
                    setedit(false);
                    setloading(false);
                  }}
                >
                  {({ values }) => (
                    <Form style={{ width: "70%" }}>
                      <Row
                        style={{
                          marginBottom: 30,
                          paddingBottom: 20,
                          borderBottom: "1px solid #eaecf0",
                        }}
                      >
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> First Name
                          </Label>
                          <FormInput
                            id={`firstName`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`firstName`}
                            placeholder={values.firstName}
                            type="text"
                            //   label=""
                          />
                        </Col>
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Last Name
                          </Label>
                          <FormInput
                            id={`lastName`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`lastName`}
                            placeholder={values.lastName}
                            type="text"
                          />
                        </Col>

                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Relationship
                          </Label>
                          <FormInput
                            id={`relation`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`relation`}
                            placeholder={values.relation}
                            type="text"
                            //   label=""
                          />
                        </Col>
                        <Col md={6} style={{ marginBottom: 32 }}>
                          <Label>
                            <Required>*</Required> Email Address
                          </Label>
                          <FormInput
                            id={`email`}
                            style={{ borderRadius: 3, marginBottom: 0 }}
                            name={`email`}
                            placeholder={values.email}
                            type="email"
                          />
                        </Col>
                        <Col md={6}>
                          <Label>
                            <Required>*</Required> Phone Number
                          </Label>
                          <PhoneInput
                            international
                            defaultCountry="KW"
                            countries={filteredCountries}
                            value={emergencyContactNo}
                            onChange={(e: any) => setemergencyContactNo(e)}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-row">
                        <ButtonComponent
                          style={{ borderRadius: 20, width: "max-content" }}
                          btnType="dark"
                          type="submit"
                          loading={loading}
                          label={loading ? "Loading..." : "Update"}
                        />
                        <ButtonComponent
                          style={{
                            borderRadius: 20,
                            width: "max-content",
                            marginLeft: 12,
                          }}
                          btnType="light"
                          onClick={() => {
                            setloading(false);
                            setedit(false);
                          }}
                          loading={false}
                          label="EXIT"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : null}
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};
