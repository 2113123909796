import React, { useRef, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Tribute from "tributejs";
import Picker from "@emoji-mart/react";
import { ButtonComponent } from "../ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";
import { AttachmentIcon } from "../../Assets";

type User = {
  id: string;
  name: string;
  slackMemberId: string;
};

type CommentEditorProps = {
  onSubmit?: (
    content: string,
    mentionedUsers: any,
    images: any
  ) => Promise<void>;
  loading: boolean;
  users: User[];
};

export const CommentEditor: React.FC<CommentEditorProps> = ({
  onSubmit,
  loading,
  users,
}) => {
  const [commentText, setCommentText] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [filePreviewUrls, setFilePreviewUrls] = useState<
    (string | ArrayBuffer)[]
  >([]);
  const [imageAsFile, setImageAsFile] = useState<File[]>([]);
  const quillRef = useRef<ReactQuill>(null);
  const tributeRef = useRef<any | null>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { handleFileUpload } = bindActionCreators(dataAction, dispatch);

  // Custom toolbar configuration
  const modules = {
    toolbar: [["bold", "italic", "strike"], ["link"]],
  };

  // Supported formats
  const formats = ["bold", "italic", "strike", "link", "image"];

  // Check if the comment text is truly empty
  const isCommentEmpty = (text: string): boolean => {
    const cleanedText = text.replace(/<[^>]+>/g, "").trim();
    return cleanedText === "";
  };

  // Initialize Tribute.js for mentions
  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      if (tributeRef.current) {
        tributeRef.current.detach(quill.root);
      }

      tributeRef.current = new Tribute({
        values: users.map((user) => ({ key: user.name, value: user.name })),
        selectTemplate: (item) => `@${item.original.key}`,
        menuItemTemplate: (item) => item.original.key,
        trigger: "@",
        noMatchTemplate: () => "",
      });

      const editor = quill.root;
      tributeRef.current.attach(editor);

      const handleEnterKey = (event: KeyboardEvent) => {
        if (event.key === "Enter" && tributeRef.current.isActive) {
          event.preventDefault();
          event.stopPropagation();

          const highlightedItem = tributeRef.current.current;
          if (highlightedItem) {
            tributeRef.current.selectItemAtIndex(
              tributeRef.current.menuSelected,
              event
            );
            tributeRef.current.hideMenu();
          }
        }
      };

      editor.addEventListener("keydown", handleEnterKey, true);

      return () => {
        editor.removeEventListener("keydown", handleEnterKey, true);
        tributeRef.current?.detach(editor);
      };
    }
  }, [users]);

  // Function to insert emoji into the editor
  const addEmoji = (emoji: any) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      editor.focus();
      const range = editor.getSelection();
      if (range) {
        const position = range.index;
        editor.insertText(position, emoji.native);
        editor.setSelection(position + emoji.native.length);
      }
    }
    setShowEmojiPicker(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const mentionedUsers = extractMentionedUsers(commentText);

    // Upload files to Firebase Storage and get download URLs
    const downloadURLs: any = await handleFileUpload(imageAsFile);

    // Pass the comment text, mentioned users, and download URLs to the parent component
    await onSubmit?.(
      commentText,
      mentionedUsers,
      downloadURLs.map((z: any) => z.url)
    );

    // Reset the editor and image states
    if (editorWrapperRef.current) {
      editorWrapperRef.current.style.height = "auto";
    }
    setCommentText("");
    setFilePreviewUrls([]);
    setImageAsFile([]);
  };

  const extractMentionedUsers = (text: string): User[] => {
    const mentionedNames = text
      .match(/@(\w+)/g)
      ?.map((mention) => mention.slice(1));
    return users.filter((user) => mentionedNames?.includes(user.name));
  };

  // Handle image upload
  const handleImageUpload = async (files: FileList | null) => {
    if (files) {
      const filesArray = Array.from(files);
      setImageAsFile((prev) => [...prev, ...filesArray]);

      // Generate preview URLs
      const previewUrls = filesArray.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise<string | ArrayBuffer>((resolve) => {
          reader.onload = () => resolve(reader.result as string | ArrayBuffer);
        });
      });

      const resolvedUrls = await Promise.all(previewUrls);
      setFilePreviewUrls((prev) => [...prev, ...resolvedUrls]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleImageUpload(event.target.files);
  };

  // Remove image from preview and file list
  const removeImage = (index: number) => {
    const newFilePreviewUrls = [...filePreviewUrls];
    newFilePreviewUrls.splice(index, 1);
    setFilePreviewUrls(newFilePreviewUrls);

    const newImageAsFile = [...imageAsFile];
    newImageAsFile.splice(index, 1);
    setImageAsFile(newImageAsFile);
  };

  return (
    <form onSubmit={handleSubmit} className="d-flex w-100 flex-column">
      <div
        ref={editorWrapperRef}
        className="editor-wrapper"
        style={{ position: "relative" }}
      >
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={commentText}
          onChange={setCommentText}
          placeholder="Type your comment here..."
          style={{ height: "100%", width: "100%" }}
          modules={modules}
          formats={formats}
        />
        <div
          className="emoji-picker-icon d-flex flex-row align-items-center"
          style={{
            position: "absolute",
            bottom: 7,
            zIndex: 99,
            cursor: "pointer",
            fontSize: 20,
            right: 13,
            height: 42,
          }}
        >
          <svg
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9998 23.3337C19.6022 23.3337 23.3332 19.6027 23.3332 15.0003C23.3332 10.398 19.6022 6.66699 14.9998 6.66699C10.3975 6.66699 6.6665 10.398 6.6665 15.0003C6.6665 19.6027 10.3975 23.3337 14.9998 23.3337Z"
              stroke="#5E5E5E"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6665 16.667C11.6665 16.667 12.9165 18.3337 14.9998 18.3337C17.0832 18.3337 18.3332 16.667 18.3332 16.667"
              stroke="#5E5E5E"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 12.5H12.51"
              stroke="#5E5E5E"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 12.5H17.51"
              stroke="#5E5E5E"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
            id="image-upload"
            multiple // Allow multiple file selection
          />
          <label
            htmlFor="image-upload"
            style={{
              cursor: "pointer",
              padding: "0px 14px",
            }}
          >
            <AttachmentIcon color={""} style={{ zIndex: 99 }} />
          </label>
          <ButtonComponent
            label="Send"
            style={{
              padding: "4.5px 14px",
              zIndex: 99,
              height: "auto",
              width: "auto",
            }}
            type="submit"
            disabled={isCommentEmpty(commentText) || loading}
            loading={loading}
            btnType={"dark"}
          />
        </div>

        {showEmojiPicker && (
          <div
            style={{
              position: "absolute",
              bottom: "60px",
              right: "10px",
              zIndex: 1000,
            }}
          >
            <Picker onEmojiSelect={addEmoji} />
          </div>
        )}
      </div>

      {/* Image Thumbnails */}
      {filePreviewUrls.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {filePreviewUrls.map((url, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                width: "50px",
                height: "50px",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <img
                src={url as string}
                alt={`preview-${index}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <button
                onClick={() => removeImage(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};
