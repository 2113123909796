import * as React from "react";

interface IApplicationIconProps {
  background: string;
}

export const ApplicationIcon: React.FunctionComponent<IApplicationIconProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.background}
        stroke={props.background}
        d="M3 11V3h8v8zm0 10v-8h8v8zm10-10V3h8v8zm0 10v-8h8v8zM5 9h4V5H5zm10 0h4V5h-4zm0 10h4v-4h-4zM5 19h4v-4H5z"
      ></path>
    </svg>
  );
};
