import React, { useEffect, useState, useRef } from 'react';
import { useSocket } from '../contexts/SocketContext'; // Use the context hook
import axios from 'axios';
import { ApiV1 } from '../API';
import { NotificationIcon } from '../Assets';
import { SubText, Text, Header, DDMenu } from './styled';

interface Notification {
  _id: string;
  userIds: string[]; // Array of user IDs
  message: string;
  type: string; // Notification type
  readBy: string[]; // Array of user IDs who have read the notification
  createdAt: string;
  link?: string; // Optional link to another page
}

interface NotificationComponentProps {
  userId: string; // userId is passed as a prop
}

export const NotificationComponent: React.FC<NotificationComponentProps> = ({ userId }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility
  const [loadedCount, setLoadedCount] = useState(10); // Number of notifications loaded
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown container
  const socket = useSocket(); // Use the socket from context

  // Request notification permission on component mount
  useEffect(() => {

    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission:', permission); // Debugging
      });
    }
  }, []);

  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await ApiV1.get(`/notifications/${userId}`);
        console.log('Fetched notifications:', response.data); // Debugging
        setNotifications(response.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    fetchNotifications();
  }, [userId]);

  // Set up socket listeners
  useEffect(() => {
    if (socket) {
      // Log WebSocket connection status
      socket.on('connect', () => {
        console.log('Connected to WebSocket server'); // Debugging
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server'); // Debugging
      });

      socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error); // Debugging
      });

      // Subscribe to notifications for the current user
      socket.emit('subscribe', userId);
      console.log('Subscribed to notifications for user:', userId); // Debugging

      // Listen for the 'notification' event
      const handleNotification = (newNotification: Notification) => {
        console.log('New notification received:', newNotification); // Debugging
        console.log('Notification permission:', Notification.permission); // Debugging

        setNotifications((prev) => [newNotification, ...prev]);

        // Show browser notification if permission is granted
        if (Notification.permission === 'granted') {
          console.log('Showing browser notification...'); // Debugging
          const notification = new Notification('New Notification', {
            body: newNotification.message,
            icon: '/path/to/icon.png', // Optional: Add an icon
          });

          // Handle click on the notification
          notification.onclick = () => {
            if (newNotification.link) {
              window.open(newNotification.link, '_blank'); // Open the link in a new tab
            }
          };
        } else {
          console.log('Notification permission not granted.'); // Debugging
        }
      };

      socket.on('notification', handleNotification);

      // Cleanup on component unmount
      return () => {
        console.log('Cleaning up socket listeners...'); // Debugging
        socket.off('notification', handleNotification); // Remove the specific listener
      };
    }
  }, [userId, socket]);

  // Mark a notification as read
  const markAsRead = async (notificationId: string) => {
    try {
      // Add the current userId to the readBy array
      await ApiV1.put(`/notifications/${notificationId}/read`, { notificationId, userId });
      setNotifications((prev) =>
        prev.map((n) =>
          n._id === notificationId
            ? { ...n, readBy: [...n.readBy, userId] } // Add userId to readBy
            : n
        )
      );
      console.log('Notification marked as read:', notificationId); // Debugging
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  // Group notifications by "Today" and "Yesterday"
  const groupNotificationsByDate = (notifications: Notification[]) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    const groupedNotifications: { [key: string]: Notification[] } = {
      Today: [],
      Yesterday: [],
      Earlier: [],
    };
  
    notifications.forEach((notification) => {
      const notificationDate = new Date(notification.createdAt);
      if (notificationDate.toDateString() === today.toDateString()) {
        groupedNotifications.Today.push(notification);
      } else if (notificationDate.toDateString() === yesterday.toDateString()) {
        groupedNotifications.Yesterday.push(notification);
      } else {
        groupedNotifications.Earlier.push(notification);
      }
    });
  
    return groupedNotifications;
  };

  // Format timestamp to relative time (e.g., "1 hour ago")
  const formatTimestamp = (timestamp: string) => {
    const now = new Date();
    const notificationDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now.getTime() - notificationDate.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  // Load more notifications
  const loadMoreNotifications = () => {
    setLoadedCount((prev) => prev + 10); // Increase loadedCount by 10
  };

  // Get visible notifications based on loadedCount
  const visibleNotifications = notifications.slice(0, loadedCount);
  const groupedNotifications = groupNotificationsByDate(visibleNotifications);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Calculate the number of unread notifications
  const unreadCount = notifications.filter(
    (n) => !n.readBy.includes(userId) // Check if userId is not in readBy array
  ).length;

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Dropdown toggle button */}
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="relative"
        style={{ border: 'none', background: 'transparent' }}
      >
        <NotificationIcon />
        {/* Red circle with unread count */}
        {unreadCount > 0 && (
          <div
            style={{
              position: 'absolute',
              top: '3px',
              right: '2px',
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              width: "16px",
              height: "16px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: "Inter",
              fontSize: "8px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            {unreadCount}
          </div>
        )}
      </button>

      {/* Dropdown content */}
      {isDropdownOpen && (
        <DDMenu className="">
          <div>
            <Header>Notifications</Header>

            {/* Dropdown content with fixed height and scroll */}
            <div className="h-[756px] overflow-y-scroll">
              {Object.entries(groupedNotifications).map(([date, notifications]) => (
                <div  key={date} className="mb-6">
                  <SubText style={{ paddingTop: "12px" }}>{date}</SubText>
                  <>
                    {notifications.map((notification) => (
                      <div
                        key={notification._id}
                        className={``}
                        onClick={() => markAsRead(notification._id)}

                        style={{ padding: "12px 0",cursor:"pointer" }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div>
                            <Text>{notification.message}</Text>
                            <br />
                            <SubText>
                              {formatTimestamp(notification.createdAt)}
                            </SubText>
                          </div>
                          {
                            !notification.readBy.includes(userId) && ( // Show mark as read button only if unread
                             <div> <div
                             style={{
                               cursor: "pointer",
                               width: 10,
                               height: 10,
                               borderRadius: 5,
                               background: "#7F56D9",
                               opacity: 1,
                             }}
                           /></div>
                            )
                          }
                          {notification.link && (
                            <a
                              href={notification.link}
                              className="mt-2 block text-blue-500 hover:text-blue-600"
                            >
                              Go to (page)
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              ))}
            </div>

            {/* Show More button */}
            {notifications.length > loadedCount && (
              <Header
                onClick={loadMoreNotifications}
style={{color:"#7F56D9",cursor:"pointer",marginTop:15}}
                >
                Show More
              </Header>
            )}
          </div>
        </DDMenu>
      )}
    </div>
  );
};