import React, { useState } from "react";
import {
  Modal,
  Form,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";
import { FilterIcon, DeleteIcon } from "../Assets";
import { Label } from "./FormInput/styled";
import { ButtonComponent } from "./ButtonComponent";
import { getApplications } from "../Services/Function";

type Props = {
  show: boolean;
  handleClose: () => void;
  phases: string[];
  statuses: string[];
  onFilterChange: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setIntakeYear: any;
  setIntakeMonth: any;
  setselectedAppStatus: any;
  setconfrimStatus: any;
  setApplications: any;
  reloadStudents: any;
  getStudentByStage?: any;
  setambassadorName?: any;
  ambassadors?: any;
  ambassadorName?: any;
  page?: any;
  users?: any;
  setselectedUser: any;
  selectedUser: any;
  setfilterApplied: any;
};

export const ApplicationFilterModal: React.FC<Props> = ({
  show,
  handleClose,
  phases,
  statuses,
  onFilterChange,
  selectedAppStatus,
  intakeYear,
  intakeMonth,
  confrimStatus,
  setIntakeYear,
  setIntakeMonth,
  setselectedAppStatus,
  setconfrimStatus,
  setApplications,
  reloadStudents,
  getStudentByStage,
  setambassadorName,
  ambassadors,
  ambassadorName,
  page,
  users,
  setselectedUser,
  selectedUser,
  setfilterApplied,
}) => {
  const [studyPrefrence, setstudyPrefrence] = useState("");

  const handlePhaseChange = (phase: string) => {
    setselectedAppStatus((prevPhases: any) =>
      prevPhases.includes(phase)
        ? prevPhases.filter((p: any) => p !== phase)
        : [...prevPhases, phase]
    );
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = Number(e.target.value);
    setIntakeYear(selectedYear > 0 ? selectedYear : undefined);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIntakeMonth(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setconfrimStatus(e.target.value);
  };

  const resetFilters = async () => {
    setselectedAppStatus([]);
    setIntakeYear(undefined);
    setIntakeMonth("");
    setconfrimStatus("");
    setfilterApplied(false);
    setstudyPrefrence("");
    setambassadorName("");
    setselectedUser({});
    reloadStudents(); // Refresh the student data
    onFilterChange(); // Notify the parent about the filter reset
    const res = await getApplications({}); // Reload applications with no filters
    setApplications(res);
    handleClose(); // Close the modal after resetting filters
  };

  const applyFilters = async () => {
    onFilterChange();
    if (
      selectedAppStatus.length > 0 ||
      intakeYear ||
      intakeMonth ||
      confrimStatus ||
      studyPrefrence ||
      ambassadorName ||
      Object.keys(selectedUser).length
    ) {
      setfilterApplied(true);
    } else {
      setfilterApplied(false);
    }
    if (ambassadorName || Object.keys(selectedUser).length) {
      getStudentByStage(page, "Applied", ambassadorName, selectedUser);
    } else if (
      selectedAppStatus.length > 0 ||
      intakeYear ||
      intakeMonth ||
      confrimStatus ||
      studyPrefrence
    ) {
      const res = await getApplications({
        status: selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
        intakeYear,
        intakeMonth,
        courseLevel:
          studyPrefrence === "Bachelors"
            ? "Undergraduate"
            : studyPrefrence === "Masters"
              ? "Postgraduate"
              : studyPrefrence,
        confrimStatus: confrimStatus.length > 0 ? confrimStatus : undefined,
      });
      setApplications(res);
    }

    handleClose(); // Close the modal after applying filters
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal side-modal fade"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Applications</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <Label>Select Phases:</Label>
            <Dropdown>
              <DropdownButton
                id="phases-dropdown"
                title={
                  selectedAppStatus.length > 0
                    ? selectedAppStatus.join(", ")
                    : "Select Phases"
                }
                variant="secondary"
              >
                {phases.map((phase) => (
                  <Dropdown.Item
                    key={phase}
                    active={selectedAppStatus.includes(phase)}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent closing the main dropdown
                      handlePhaseChange(phase);
                    }}
                  >
                    {phase}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Dropdown>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Select Intake Year:</Label>
            <Form.Control
              as="select"
              value={intakeYear || ""}
              onChange={(e: any) => handleYearChange(e)}
            >
              <option value="">Select</option>
              {[...Array(10)].map((_, i) => {
                const year = new Date().getFullYear() + i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Select Intake Month:</Label>
            <Form.Control
              as="select"
              value={intakeMonth || ""}
              onChange={(e: any) => handleMonthChange(e)}
            >
              <option value="">Select</option>
              {[...Array(12)].map((_, i) => {
                const month = new Date(0, i).toLocaleString("default", {
                  month: "long",
                });
                return (
                  <option key={month} value={month}>
                    {month}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Study Preference:</Label>
            <Form.Control
              as="select"
              value={studyPrefrence || ""}
              onChange={(e: any) => setstudyPrefrence(e.target.value)}
            >
              <option value="">Select</option>
              {["Foundation", "Bachelors", "Masters"].map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Select Status:</Label>
            <Form.Control
              as="select"
              value={confrimStatus || ""}
              onChange={(e: any) => handleStatusChange(e)}
            >
              <option value="">Select</option>
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Select Assigned User:</Label>
            <Form.Control
              as="select"
              value={selectedUser.id || ""}
              onChange={(e: any) => {
                const selectedUserId = e.target.value;
                const user = users.find(
                  (user: any) => user.id === selectedUserId
                );
                setselectedUser(user || {});
              }}
            >
              <option value="">Select</option>
              {users.map((a: any) => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Label>Select Ambassador:</Label>
            <Form.Control
              as="select"
              value={ambassadorName || ""}
              onChange={(e: any) => setambassadorName(e.target.value)}
            >
              <option value="">Select</option>
              {ambassadors.map((a: any) => (
                <option key={a} value={a.name}>
                  {a.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <ButtonComponent
          onClick={resetFilters}
          label={"Reset Filters"}
          loading={false}
          btnType={"light"}
        />
        <ButtonComponent
          onClick={applyFilters}
          label={"Apply Filters"}
          loading={false}
          btnType={"dark"}
        />
      </Modal.Footer>
    </Modal>
  );
};
