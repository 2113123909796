import * as React from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import {
  ApplicationStatus,
  Table,
  TBody,
  Td,
  TdEmail,
  TdText,
  Th,
  THead,
  Tr,
  UserSection,
} from "./styled";
import {
  getApplications,
  updateStudent,
  updateApplication,
  getStudentDocById,
  getCommentsByStudentid,
} from "../../Services/Function";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { Loader } from "../Loader/Loader";
import { ReadyToApply } from "../ReadyToApply";
import { DragDropModal } from "../DragDropModal";
import { DotIcon } from "../../Assets";
import { CommentsModal } from "../CommentsModal";

interface IApplicationViewProps {
  reloadStudents: Function;
  students: Array<any>;
  page: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setApplications: any;
  applications: any;
  auth: any; // Add auth to props
}

export const ApplicationView: React.FC<IApplicationViewProps> = ({
  students,
  selectedAppStatus,
  intakeYear,
  intakeMonth,
  confrimStatus,
  setApplications,
  applications,
  reloadStudents,
  auth, // Destructure auth from props
}) => {
  const [loading, setLoading] = React.useState(false);
  const [draggingApp, setDraggingApp] = React.useState<any>(null); // Track the dragged application
  const [draggingStudentId, setDraggingStudentId] = React.useState<
    string | null
  >(null); // Track the student ID of the dragged application
  const navigate = useNavigate();
  const [modalShow, setmodalShow] = React.useState<any>(false);
  const [documents, setdocuments] = React.useState([]);
  const [unconditionalOffer, setunconditionalOffer] = React.useState(false);
  const [targetPhase, settargetPhase] = React.useState("");
  const [comments, setcomments] = React.useState([]);
  const [showModal, setshowModal] = React.useState(false);
  const [studentSelected, setstudentSelected] = React.useState<any>({});

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    confrimStatus,
    intakeMonth,
    intakeYear,
    selectedAppStatus,
    setApplications,
  ]);

  const applicationPhase = [
    "Initiated",
    "Submitted",
    "Conditional offer",
    "Unconditional offer",
    "Confirmation",
    "FG/BS",
    "CAS Received",
    "Done",
  ];

  const filterApplicationsByStatus = (status: string) => {
    return applications.filter((app: any) =>
      app.portalApplicationStatus?.applicationPhases.some(
        (phase: any) =>
          phase.phaseState === "Completed" &&
          phase.status.toUpperCase() === status.toUpperCase() &&
          phase.isPrevious
      )
    );
  };

  const filteredStudents = students.filter((student) =>
    applicationPhase.some((phase) =>
      filterApplicationsByStatus(phase).some((app: any) =>
        student.applications.includes(app._id)
      )
    )
  );

  // Handle drag start
  const handleDragStart = (app: any, studentId: string) => {
    setDraggingApp(app);
    setDraggingStudentId(studentId);
  };
  const reloadDocs = async () => {
    const resp = await getStudentDocById(draggingApp.studentId);
    setdocuments(resp.data);
  };

  const getComments = async (id: any) => {
    const res = await getCommentsByStudentid(id);

    setcomments(res.data);
  };

  const reoloadApplication = async () => {
    try {
      setLoading(true);
      const res = await getApplications({
        status: selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
        intakeYear: intakeYear,
        intakeMonth: intakeMonth,
        confrimStatus: confrimStatus.length > 0 ? confrimStatus : undefined,
      });
      setApplications(res);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };
  // Handle drop event
  const handleDrop = async (
    e: React.DragEvent,
    targetPhase: string, // The target phase where the application is dropped
    student: any
  ) => {
    e.preventDefault();
    setstudentSelected(student);

    if (
      draggingApp &&
      draggingStudentId === student.id &&
      student.applications.includes(draggingApp._id)
    ) {
      const sourcePhase: any = applicationPhase.find((phase) =>
        filterApplicationsByStatus(phase).some(
          (app: any) => app._id === draggingApp._id
        )
      );

      if (targetPhase === sourcePhase) {
        return;
      }

      // Check if the target phase is before the current phase
      const isTargetPhaseBeforeCurrent =
        applicationPhase.indexOf(targetPhase) <
        applicationPhase.indexOf(sourcePhase);

      if (isTargetPhaseBeforeCurrent) {
        // Logic for moving backward (to stages before the current phase)
        if (
          draggingApp &&
          draggingStudentId === student.id &&
          student.applications.includes(draggingApp._id)
        ) {
          setLoading(true);
          // Check if the target phase is "Confirmation" or a stage ahead of it
          const isTargetConfirmationOrAhead =
            applicationPhase.indexOf(targetPhase) >=
            applicationPhase.indexOf("Confirmation");

          // Check if the dragged application is already in "Confirmation" or a stage ahead of it
          const isDraggedAppInConfirmationOrAhead =
            applicationPhase.indexOf(
              draggingApp.portalApplicationStatus?.currentPhase
            ) >= applicationPhase.indexOf("Confirmation");

          // Check if the student is on hold or if any application has finalChoice false

          const hasFinalChoiceFalseApplication = applications.some(
            (app: any) => !app.finalChoice && app.studentId === student.id
          );

          // Rule 1: Only one application can be in "Confirmation" or stages ahead of it
          if (
            isTargetConfirmationOrAhead &&
            !isDraggedAppInConfirmationOrAhead
          ) {
            const hasConfirmationOrAheadApplication = applications.some(
              (app: any) =>
                app.studentId === student.id &&
                app._id !== draggingApp._id && // Exclude the dragged application
                applicationPhase.indexOf(
                  app.portalApplicationStatus?.currentPhase
                ) >= applicationPhase.indexOf("Confirmation")
            );

            if (hasConfirmationOrAheadApplication) {
              alert(
                "Only one application can be in the Confirmation stage or stages ahead of it."
              );
              return; // Stop further execution
            }
          }

          // Rule 2: If the student is on hold or has a finalChoice false application, prevent moving to "Confirmation" or stages ahead
          if (!hasFinalChoiceFalseApplication && isTargetConfirmationOrAhead) {
            alert(
              "Applications in stages before Confirmation cannot move to Confirmation or stages ahead when the student is on hold or has a final choice false application."
            );
            return; // Stop further execution
          }

          // Rule 3: Only the application with finalChoice true can move ahead after Confirmation
          if (isTargetConfirmationOrAhead && !draggingApp.finalChoice) {
            alert(
              "Only the application with finalChoice true can move ahead after the Confirmation stage."
            );
            return; // Stop further execution
          }

          // Update application phases logic
          const stages = draggingApp.portalApplicationStatus?.applicationPhases;

          const selectedStatus = targetPhase; // The target phase is the selected status
          const selectedStageIndex = stages.findIndex(
            (stage: any) => stage.status === selectedStatus
          );

          if (selectedStageIndex !== -1) {
            // Loop through all stages to update phase states and flags
            stages.forEach((stage: any, index: any) => {
              if (selectedStatus === "Initiated") {
                // Special handling for the "INITIATED" stage
                if (index === selectedStageIndex) {
                  // The "INITIATED" stage
                  stage.phaseState = "Completed";
                  stage.isCurrent = false;
                  stage.isPrevious = true;
                } else if (index === selectedStageIndex + 1) {
                  // The next stage ("Submitted")
                  stage.phaseState = "AwaitingResponseStudent";
                  stage.isCurrent = true;
                  stage.isPrevious = false;
                } else {
                  // All other stages
                  stage.phaseState = "Upcoming";
                  stage.isCurrent = false;
                  stage.isPrevious = false;
                }
              } else {
                // Handle other stages
                if (index < selectedStageIndex) {
                  // For stages before the selected stage
                  stage.phaseState = "Completed";
                  stage.isCurrent = false;
                  stage.isPrevious = index === selectedStageIndex - 1; // Only the stage before the target stage is marked as isPrevious true
                } else if (index === selectedStageIndex) {
                  // The selected stage is set as current with phaseState 'AwaitingResponseStudent'
                  stage.phaseState = "AwaitingResponseStudent";
                  stage.isCurrent = true;
                  stage.isPrevious = false;
                } else {
                  // Stages after the selected stage are set to Upcoming
                  stage.phaseState = "Upcoming";
                  stage.isCurrent = false;
                  stage.isPrevious = false;
                }
              }
            });
          }

          // Handle confirmation stage logic
          const confirmationStage = stages.find(
            (stage: any) => stage.status === "Confirmation"
          );

          if (
            confirmationStage.phaseState === "AwaitingResponseStudent" ||
            confirmationStage.phaseState === "Completed"
          ) {
            await updateStudent(draggingApp?.studentId, {
              onHold: true,
            });
            await updateApplication(draggingApp._id, {
              finalChoice: true,
            });
          } else if (
            confirmationStage.phaseState === "Upcoming" &&
            draggingApp.finalChoice
          ) {
            console.log(confirmationStage.phaseState);
            await updateApplication(draggingApp._id, {
              finalChoice: false,
            });
            await updateStudent(draggingApp?.studentId, {
              onHold: false,
            });
          }

          // Update the application with the new phases and other details
          await updateApplication(draggingApp._id, {
            portalApplicationStatus: {
              applicationPhases: stages,
            },
            phaseChanged: true,
            editor: auth.userDetails, // Assuming `auth` is available in the scope
          });

          // Reload applications and students
          reoloadApplication();
          reloadStudents();
          // Reset dragging state
          setDraggingApp(null);
          setDraggingStudentId(null);
        }
      } else {
        // Logic for moving forward (to stages after the current phase)
        settargetPhase(targetPhase);
        const resp = await getStudentDocById(draggingApp.studentId);
        setdocuments(
          resp.data.filter(
            (z: any) =>
              z.documentType === "Issued Document" &&
              z.applicationId === draggingApp._id
          )
        );

        setmodalShow("readytoApply");
      }
    }
  };
  // Handle drag stop to ensure the application doesn't stick to the cursor
  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    setDraggingApp(null); // Reset dragging state immediately
    setDraggingStudentId(null); // Reset student ID
  };

  // Handle drag over to allow dropping
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return (
    <div className="table-responsive">
      {loading ? (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Loader height="200px" width="200px" />
        </div>
      ) : (
        <Table className="table">
          <THead>
            <Tr>
              <Th scope="col">Name</Th>
              {applicationPhase.map((phase) => (
                <Th scope="col" key={phase}>
                  {phase}
                </Th>
              ))}
            </Tr>
          </THead>
          {filteredStudents.map((student) => (
            <TBody key={student.id}>
              <Tr style={{ borderBottom: "1px solid #eaecf0" }}>
                <Td style={{ verticalAlign: "top" }}>
                  <div className="d-flex">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle"
                        style={{ paddingLeft: 0, paddingRight: 8 }}
                        id="dropdown-custom-components"
                      >
                        <DotIcon style={{ cursor: "pointer" }} />
                      </Dropdown.Toggle>

                      {/* {showMenu === z.id ? ( */}
                      <Dropdown.Menu className="dd-custom-menu">
                        <Dropdown.Item
                          className="dd-custom-items"
                          onClick={async () => {
                            setshowModal(!showModal);
                            getComments(student.id);
                          }}
                        >
                          Comments
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="dd-custom-items"
                          onClick={async () => {
                            // setdeleteModal(true);
                            // setselectedStudent(z);
                          }}
                        >
                          Delete Student
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <UserSection>
                      <div className="d-flex justify-content-start flex-column">
                        <TdText
                          onClick={() =>
                            navigate(`/student/about/${student.id}`)
                          }
                        >
                          {student.firstName} {student.lastName}
                        </TdText>
                        <TdEmail>{student.phoneNo}</TdEmail>
                      </div>
                    </UserSection>
                  </div>
                </Td>
                {applicationPhase.map((phase) => (
                  <Td
                    key={phase}
                    onDrop={(e) => handleDrop(e, phase, student)}
                    onDragOver={
                      draggingApp &&
                      draggingStudentId === student.id &&
                      student.applications.includes(draggingApp._id)
                        ? handleDragOver
                        : undefined // Only allow drag-over for the same student
                    }
                    style={{
                      backgroundColor:
                        draggingApp && draggingStudentId !== student.id
                          ? "#f0f0f0" // Gray out other students' rows
                          : "inherit",
                      opacity:
                        draggingApp && draggingStudentId !== student.id
                          ? 0.5 // Gray out other students' rows
                          : 1,
                      verticalAlign: "top",
                    }}
                  >
                    <div className="application-grid">
                      {filterApplicationsByStatus(phase)
                        .filter((app: any) =>
                          student.applications.includes(app._id)
                        )
                        .map((app: any, key: any) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/student/applicationDetails/${student.id}/${app._id}`
                              )
                            }
                            key={app._id}
                            className="application-item"
                          >
                            {draggingApp?._id === app._id ? (
                              <Draggable
                                onStart={() => handleDragStart(app, student.id)}
                                onStop={handleDragStop} // Reset on stop
                                position={{ x: 0, y: 0 }} // Reset position after drop
                              >
                                <ApplicationStatus
                                  style={{ cursor: "grab" }}
                                  borderColor={app.rejected ? "grey" : "black"}
                                >
                                  <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="top"
                                    overlay={
                                      <Popover id="dynamic-popover">
                                        <Popover.Body className="application-icon-hover-con">
                                          <div>{app.institute.name}</div>
                                          <div>{app.courseLevel}</div>
                                          <div>{app.courseName}</div>
                                          <div>{`${app.intakeMonth} ${app.intakeYear}`}</div>
                                          <div>{`${app.comments}`}</div>
                                          <div>{`Created At: ${moment(
                                            app.createdAt
                                          ).format("DD-MM-YYYY hh:mma")}`}</div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <img
                                      style={{
                                        width: "100%",
                                        borderRadius: 12,
                                        height: "100%",
                                        opacity: app.rejected ? 0.5 : 1,
                                      }}
                                      src={`https://storage.googleapis.com/uapply/${app.institute.logoUrl}`}
                                      alt="img"
                                    />
                                  </OverlayTrigger>
                                </ApplicationStatus>
                              </Draggable>
                            ) : (
                              <ApplicationStatus
                                onClick={() =>
                                  navigate(
                                    `/student/applicationDetails/${student.id}/${app._id}`
                                  )
                                }
                                style={{ cursor: "grab" }}
                                borderColor={app.rejected ? "grey" : "black"}
                                onMouseDown={() =>
                                  handleDragStart(app, student.id)
                                }
                              >
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  overlay={
                                    <Popover id="dynamic-popover">
                                      <Popover.Body className="application-icon-hover-con">
                                        <div>{app.institute.name}</div>
                                        <div>{app.courseLevel}</div>
                                        <div>{app.courseName}</div>
                                        <div>{`${app.intakeMonth} ${app.intakeYear}`}</div>
                                        <div>{`${app.comments}`}</div>
                                        <div>{`Created At: ${moment(
                                          app.createdAt
                                        ).format("DD-MM-YYYY hh:mma")}`}</div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      borderRadius: 12,
                                      height: "100%",
                                      opacity: app.rejected ? 0.5 : 1,
                                    }}
                                    src={`https://storage.googleapis.com/uapply/${app.institute.logoUrl}`}
                                    alt="img"
                                  />
                                </OverlayTrigger>
                              </ApplicationStatus>
                            )}
                          </div>
                        ))}
                    </div>
                  </Td>
                ))}
              </Tr>
            </TBody>
          ))}
        </Table>
      )}

      {showModal ? (
        <CommentsModal
          selectedStudent={{ id: draggingStudentId }}
          comments={comments}
          getComments={(id: any) => getComments(id)}
          show={showModal}
          handleClose={() => setshowModal(false)}
        />
      ) : null}

      {modalShow === "readytoApply" ? (
        <DragDropModal
          show={modalShow === "readytoApply"}
          reloadApplication={reoloadApplication}
          documents={documents}
          application={draggingApp}
          handleClose={() => {
            setmodalShow(null);
            setunconditionalOffer(false);
          }}
          reloadDocuments={reloadDocs}
          unconditionalOffer={unconditionalOffer}
          studentId={draggingStudentId}
          reloadStudents={reloadStudents}
          targetPhase={targetPhase}
          studentSelected={studentSelected}
        />
      ) : null}
    </div>
  );
};
