// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 2px;
`;
export const Required = styled.span`
  color: #ba1b23;
`;
export const EditSectionHeader = styled.span`
  color: #7f56d9;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`;
export const EditSectionCard = styled.div`
  border-radius: 12px;
  padding: 12px;

  background: #f9f5ff;
`;
export const EditSectionLabel = styled.span`
  color: #667085;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
export const EditSectionValue = styled.span`
  color: #1d2939;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;
export const EditSectionText = styled.div`
  color: #1d2939;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;
export const EditText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0065ff;
`;

export const ToggleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
`;

export const AddContactsText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.75px;
  margin-top: 32px;
  color: #0052cc;
  cursor: pointer;
`;

export type DocumentSubtextProps = {
  fontWeight?: number;
  size?: string;
};
export const DocumentSubtext = styled.span<DocumentSubtextProps>(
  ({ fontWeight, size }) => `
  font-family: "Open Sans";
  font-style: normal;
  font-weight: ${fontWeight};
  font-size: ${size ? size : "14px"};
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #5e6c84;
  `
);

export const Pointer = styled.span`
  background: rgba(0, 0, 0, 0.24);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 12px;
`;
