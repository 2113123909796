/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { debounce } from "debounce";
import {
  ApplicationView,
  ButtonComponent,
  Loader,
  PaginationComponent,
  ListView,
  BulkMessageModal,
  ToggleButton,
  ApplicationFilter,
} from "../Components";

import {
  Container,
  FilterBtn,
  HeaderText,
  PaginationSection,
  PaginationSectionText,
  RowCol,
  SearchBar,
  ToggleText,
} from "./styled/students";
// import icon from "../Assets/Icons/SearchIcon.svg";
import { FilterIcon } from "../Assets";
import {
  getAmbassadors,
  getApplications,
  getSponsorStudents,
  getStudents,
  getUsersWithoutPagination,
  searchStudents,
} from "../Services/Function";
import ModalComponent from "../Components/Modal";
import { State } from "../state";
import { Dropdown } from "react-bootstrap";
import CreateModal from "../Components/CreateModal/CreateModal";
// const ListView = React.lazy(() => import("../Components/ListView/index"));

interface IStudentsProps {}

export const Students: React.FunctionComponent<IStudentsProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");
  const [listView, setlistView] = React.useState(false);
  const [students, setstudents] = React.useState([]);
  const [studentsUnqualified, setstudentsUnqualified] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");
  const [page, setpage] = React.useState(1);
  const [totalPages, settotalPages] = React.useState("");
  const [qualified, setqualified] = React.useState(true);
  const [stages, setstages] = React.useState("Applied");
  const [totalResults, settotalResults] = React.useState("");
  const [selectedDegree, setselectedDegree] = React.useState<any>("");
  const [selectedCountry, setselectedCountry] = React.useState<any>("");
  const [filterApplied, setfilterApplied] = React.useState(false);
  const [selectedResidence, setselectedResidence] = React.useState("");
  const [selectedStatus, setselectedStatus] = React.useState<any>("");
  const [loading, setloading] = React.useState(false);
  const [selectedStudentArr, setselectedStudentArr] = React.useState<any>([]);
  const [modalShow, setmodalShow] = React.useState(false);
  const [limit, setlimit] = React.useState(10);
  const [ambassadorName, setambassadorName] = React.useState("");
  const [sponsored, setsponsored] = React.useState(false);
  const [sponsoredStudents, setsponsoredStudents] = React.useState([]);
  const [applications, setApplications] = React.useState<any[]>([]);
  const [selectedAppStatus, setselectedAppStatus] = React.useState("");
  const [intakeYear, setintakeYear] = React.useState(null);
  const [intakeMonth, setintakeMonth] = React.useState(null);
  const [confrimStatus, setconfrimStatus] = React.useState("");
  const [ambassadors, setambassadors] = React.useState([]);
  const [users, setusers] = React.useState([]);
  const [selectedUser, setselectedUser] = React.useState<any>({});

  const waKey = useSelector((state: State) => state.data.waKey);

  React.useEffect(() => {
    async function fetchData() {
      try {
        setloading(true);
        const users = await getUsersWithoutPagination();
        setusers(users);
        if (stages === "Applied") {
          const students = await getStudents({
            sortBy: "createdAt:desc",
            limit: 10,
            page: page,
            stage: "Applied",
          });
          const ambassador = await getAmbassadors();
          const res = await getApplications({
            status:
              selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
            intakeYear: intakeYear,
            intakeMonth: intakeMonth,
            confrimStatus: confrimStatus.length > 0 ? confrimStatus : undefined,
          });

          setApplications(res);
          setambassadors(ambassador.results);
          settotalPages(students.totalPages || null);
          setstudents(students.results);
          settotalResults(students.totalResults);
        }

        setloading(false);
      } catch (error) {
        alert(error);
        setloading(false);
      }
    }
    fetchData();
  }, []);

  const reloadStudents = async () => {
    try {
      setloading(true);
      if (stages === "Applied") {
        const students = await getStudents({
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
          stage: "Applied",
        });
        settotalPages(students.totalPages || null);
        setstudents(students.results);
        settotalResults(students.totalResults);
      }
      setloading(false);
    } catch (error) {
      alert(error);
      setloading(false);
    }
  };

  const getStudentByStage = async (
    pegeValue?: number,
    stage?: string,
    ambassadorName?: string,
    selectedUser?: any
  ) => {
    try {
      // setloading(true);
      setpage(pegeValue ? pegeValue : 1);
      const studentsUnqualified = await getStudents({
        sortBy: "createdAt:desc",
        limit: limit,
        page: pegeValue ? pegeValue : 1,
        stage: stage,
        ambassadorName: ambassadorName ? ambassadorName : "",
        assignedUserId: selectedUser ? selectedUser.id : "",
      });
      settotalPages(studentsUnqualified.totalPages || null);
      settotalResults(studentsUnqualified.totalResults);
      setstudents(studentsUnqualified.results);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const removeFilter = async () => {
    setfilterApplied(false);
    setselectedDegree("");
    setselectedCountry("");
    setselectedResidence("");
    setselectedStatus("");
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }
  let options = {
    sortBy: "createdAt:desc",
    stage: stages,
    ambassadorName: ambassadorName,
    assignedUserId: selectedUser.id,
  };

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <HeaderText>Students</HeaderText>
        {/* {selectedStudentArr.length > 0 && waKey ? (
          <ButtonComponent
            btnType="dark"
            style={{ width: "max-content" }}
            loading={false}
            onClick={() => setmodalShow(true)}
            label="Send Bulk Message"
          />
        ) : null} */}
        <ButtonComponent
          btnType="dark"
          style={{ width: "max-content" }}
          loading={false}
          onClick={() => setmodalShow(true)}
          label="Add Students"
        />
      </div>
      <div className="search-con" style={{ marginTop: 32 }}>
        <RowCol>
          <div style={{ position: "relative" }}>
            <SearchBar
              onChange={debounce(async (e: any) => {
                setsearchText(e.target.value.trim());
                if (e.target.value === "") {
                  await getStudentByStage(page, stages);
                } else {
                  const data = await searchStudents(
                    { text: e.target.value.trim() },
                    {
                      stage: stages,
                      sortBy: "createdAt:desc",
                      limit: limit,
                      page: 1,
                    }
                  );
                  setpage(1);
                  if (qualified) {
                    setstudents(data.results);
                  } else {
                    setstudents(data.results);
                  }
                  settotalResults(data.totalResults);
                  settotalPages(data.totalPages);
                }
              }, 500)}
              placeholder="Search Students"
            />
            {/* <img
              style={{ position: "absolute", top: 11, left: 8 }}
              src={icon}
              alt="icon"
            /> */}
          </div>
          <ApplicationFilter
            users={users}
            phases={[
              "Initiated",
              "Submitted",
              "Conditional offer",
              "Unconditional offer",
              "Confirmation",
              "FG/BS",
              "CAS Received",
              "Done",
            ]}
            getStudentByStage={getStudentByStage}
            setambassadorName={setambassadorName}
            ambassadors={ambassadors}
            ambassadorName={ambassadorName}
            page={page}
            statuses={["Offer on KCO", "KCO Approved", "Status 3"]}
            onFilterChange={() =>
              console.log(
                selectedAppStatus,
                intakeYear,
                intakeMonth,
                confrimStatus
              )
            }
            reloadStudents={() => reloadStudents()}
            selectedAppStatus={selectedAppStatus}
            intakeYear={intakeYear}
            intakeMonth={intakeMonth}
            confrimStatus={confrimStatus}
            setIntakeYear={setintakeYear}
            setIntakeMonth={setintakeMonth}
            setselectedAppStatus={setselectedAppStatus}
            setconfrimStatus={setconfrimStatus}
            setApplications={setApplications}
            setselectedUser={setselectedUser}
            selectedUser={selectedUser}
          />
        </RowCol>
        {stages === "NotApplied" ? null : (
          <div className="d-flex flex-row align-items-center res-margintop-15">
            <ToggleText>List view</ToggleText>
            <ToggleButton
              label="List"
              onChange={() => {
                setloading(true);
                setlistView(!listView);
                setloading(false);
              }}
              checked={listView}
            />
            <ToggleText>Application view</ToggleText>
          </div>
        )}
      </div>

      <div className="d-flex flex-row" style={{ marginTop: 20 }}>
        <ButtonComponent
          onClick={async () => {
            setloading(true);
            setstages("NotApplied");
            await getStudentByStage(page, "NotApplied");
            setloading(false);
          }}
          btnType="light"
          loading={false}
          label="Not Applied"
          style={{
            marginRight: 10,
            background: stages === "NotApplied" ? "#7F56D9" : "#fff",
            color: stages === "NotApplied" ? "#fff" : "#000",
          }}
        />
        <ButtonComponent
          onClick={async () => {
            setloading(true);
            setstages("Applied");
            await getStudentByStage(page, "Applied");
            setloading(false);
          }}
          btnType="light"
          loading={false}
          label="Applied"
          style={{
            marginRight: 10,
            background: stages === "Applied" ? "#7F56D9" : "#fff",
            color: stages === "Applied" ? "#fff" : "#000",
          }}
        />
        <ButtonComponent
          onClick={async () => {
            setloading(true);
            setstages("Enrolled");
            await getStudentByStage(page, "Enrolled");
            setloading(false);
          }}
          btnType="light"
          loading={false}
          label="Enrolled"
          style={{
            background: stages === "Enrolled" ? "#7F56D9" : "#fff",
            color: stages === "Enrolled" ? "#fff" : "#000",
          }}
        />
        <ButtonComponent
          onClick={async () => {
            setloading(true);
            setstages("Lost");
            await getStudentByStage(page, "Lost");
            setloading(false);
          }}
          btnType="light"
          loading={false}
          label="Lost"
          style={{
            background: stages === "Lost" ? "#7F56D9" : "#fff",
            color: stages === "Lost" ? "#fff" : "#000",
            marginLeft: 10,
          }}
        />
      </div>

      {qualified ? (
        <div style={{ marginTop: 16 }}>
          {!listView ? (
            <div>
              {loading ? (
                <Loader width="200px" height="200px" />
              ) : (
                <ListView
                  reloadStudents={async (pegeValue: number) => {
                    await getStudentByStage(pegeValue, stages);
                  }}
                  applications={applications}
                  setselectedStudentArr={setselectedStudentArr}
                  selectedStudentArr={selectedStudentArr}
                  page={page}
                  stages={stages}
                  students={students}
                />
              )}
            </div>
          ) : (
            <>
              {loading ? (
                <Loader width="200px" height="200px" />
              ) : (
                <ApplicationView
                  reloadStudents={async () => {
                    reloadStudents();
                  }}
                  page={page}
                  students={students}
                  selectedAppStatus={selectedAppStatus}
                  intakeYear={intakeYear}
                  intakeMonth={intakeMonth}
                  confrimStatus={confrimStatus}
                  setApplications={setApplications}
                  applications={applications}
                  auth={auth}
                />
              )}
            </>
          )}
        </div>
      ) : sponsored ? (
        <div style={{ marginTop: 16 }}>
          {loading ? (
            <Loader width="200px" height="200px" />
          ) : (
            <ListView
              reloadStudents={async (pegeValue: number) => {
                await getStudentByStage(pegeValue, stages);
              }}
              stages={stages}
              setselectedStudentArr={setselectedStudentArr}
              selectedStudentArr={selectedStudentArr}
              page={page}
              students={students}
            />
          )}
        </div>
      ) : (
        <div style={{ marginTop: 16 }}>
          {loading ? (
            <Loader width="200px" height="200px" />
          ) : (
            <ListView
              reloadStudents={async (pegeValue: number) => {
                await getStudentByStage(pegeValue, stages);
              }}
              stages={stages}
              setselectedStudentArr={setselectedStudentArr}
              selectedStudentArr={selectedStudentArr}
              page={page}
              students={students}
            />
          )}
        </div>
      )}

      {loading ? null : (
        <PaginationSection>
          <PaginationSectionText>
            {`Showing ${(page - 1) * 10 + 1} to ${
              page * 10
            } of ${totalResults} results`}
          </PaginationSectionText>
          <PaginationComponent
            onChangePrev={async () => {
              setpage(page > 1 ? page - 1 : 1);

              if (searchText) {
                const data = await searchStudents(
                  { text: searchText },
                  {
                    sortBy: "createdAt:desc",
                    limit: limit,
                    page: page - 1,
                    stage: stages,
                  }
                );
                setstudents(data.results);

                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              } else {
                const data = await getStudents(
                  Object.entries(options).reduce(
                    (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                    { limit: limit, page: page - 1 }
                  )
                );
                setstudents(data.results);

                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              }
            }}
            totalPage={totalPages}
            onPageClick={async (x: any) => {
              setpage(parseInt(x));

              if (searchText) {
                const data = await searchStudents(
                  { text: searchText },
                  {
                    sortBy: "createdAt:desc",
                    limit: limit,
                    page: x,
                    stage: stages,
                  }
                );
                setstudents(data.results);

                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              } else {
                const data = await getStudents(
                  Object.entries(options).reduce(
                    (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                    { limit: limit, page: x }
                  )
                );
                setstudents(data.results);

                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              }
            }}
            page={page}
            onChange={async () => {
              setpage(page + 1);
              if (searchText) {
                const data = await searchStudents(
                  { text: searchText },
                  {
                    sortBy: "createdAt:desc",
                    limit: limit,
                    page: page + 1,
                    stage: stages,
                  }
                );
                setstudents(data.results);

                settotalResults(data.totalResults);
                settotalPages(data.totalPages);
              } else {
                if (students?.length === 10) {
                  const data = await getStudents(
                    Object.entries(options).reduce(
                      (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                      { limit: limit, page: page + 1 }
                    )
                  );
                  setstudents(data.results);

                  settotalResults(data.totalResults);
                  settotalPages(data.totalPages);
                }
              }
            }}
          />
        </PaginationSection>
      )}

      {/* <BulkMessageModal
        waKey={waKey}
        show={modalShow}
        setselectedStudentArr={setselectedStudentArr}
        selectedStudentArr={selectedStudentArr}
        handleClose={() => setmodalShow(false)}
      /> */}
      <CreateModal
        show={modalShow}
        handleClose={() => setmodalShow(false)}
        selectedCountry={selectedCountry}
        setselectedCountry={setselectedCountry}
        selectedResidence={selectedResidence}
        reloadStudents={reloadStudents}
        setselectedResidence={setselectedResidence}
        setselectedStatus={setselectedStatus}
        selectedStatus={selectedStatus}
      />
    </Container>
  );
};
