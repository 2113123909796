import * as React from "react";

interface IDetailViewProps {
  style?: any;
}

export const DetailView: React.FunctionComponent<IDetailViewProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      style={props.style}
      viewBox="0 0 16 16"
    >
      <path
        fill="#667085"
        d="M7.336 11.334h1.333v-4H7.336zm.667-5.333a.65.65 0 0 0 .475-.192.65.65 0 0 0 .191-.475.65.65 0 0 0-.191-.475.65.65 0 0 0-.475-.192.65.65 0 0 0-.475.192.65.65 0 0 0-.192.475q0 .283.192.475a.65.65 0 0 0 .475.192m0 8.666a6.5 6.5 0 0 1-2.6-.525 6.7 6.7 0 0 1-2.117-1.425 6.7 6.7 0 0 1-1.425-2.116A6.5 6.5 0 0 1 1.336 8q0-1.385.525-2.6a6.7 6.7 0 0 1 1.425-2.117q.9-.9 2.117-1.425a6.5 6.5 0 0 1 2.6-.525q1.383 0 2.6.525 1.216.525 2.116 1.425t1.425 2.117q.525 1.215.525 2.6 0 1.383-.525 2.6a6.7 6.7 0 0 1-1.425 2.116q-.9.9-2.116 1.425a6.5 6.5 0 0 1-2.6.525m0-1.333q2.233 0 3.783-1.55t1.55-3.783-1.55-3.784-3.783-1.55-3.784 1.55-1.55 3.784 1.55 3.783 3.784 1.55"
      ></path>
    </svg>
  );
};
