import * as React from "react";
import { Button } from "./styled";

interface IButtonComponentProps {
  label: string;
  loading: boolean;
  btnType: string;
  style?: object;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  icon?: any;
}

export const ButtonComponent: React.FunctionComponent<IButtonComponentProps> = (
  props
) => {
  return (
    <Button
      style={{
        ...props.style,
        opacity: props.disabled || props.loading ? 0.5 : 1,
      }}
      disabled={props.disabled || props.loading}
      className={props.className}
      color={props.btnType}
      type={props.type ? props.type : "submit"}
      onClick={(e) => props.onClick?.(e)}
    >
      {props.icon} {props.label}
    </Button>
  );
};
