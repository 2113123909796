import * as React from "react";

interface IAttachmentIconProps {
  color: string;
  style: object;
}

export const AttachmentIcon: React.FunctionComponent<IAttachmentIconProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#5E5E5E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="m17.867 9.208-7.659 7.658a5.003 5.003 0 1 1-7.075-7.075l7.659-7.658a3.335 3.335 0 1 1 4.716 4.717l-7.666 7.658a1.668 1.668 0 0 1-2.359-2.358l7.075-7.067"
      ></path>
    </svg>
  );
};
