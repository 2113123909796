// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const SubText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #667085;
`;



export const Header = styled.span`

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #1D2939;

`


export const Text = styled.span`/* Иван Иванов создал заявку на оптуск */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #1D2939;

`


export const DDMenu = styled.div`
overflow-y:scroll;
padding: 24px !important;
height: 656px;
background: #FFFFFF;
box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
border-radius: 8px;
position:absolute;
    right: 0;
    top:45px;
    width: 501px;
`