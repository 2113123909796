import * as React from "react";

interface IPassportViewProps {
  color: string;
  style?: object;
  onClick?: any;
}

export const PassportView: React.FunctionComponent<IPassportViewProps> = (
  props
) => {
  return (
    <svg
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      style={props.style}
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color ? props.color : "#7F56D9"}
        d="M4 22q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 20V9q0-.825.587-1.412A1.93 1.93 0 0 1 4 7h5V4q0-.824.588-1.412A1.93 1.93 0 0 1 11 2h2q.825 0 1.412.587Q15 3.176 15 4v3h5q.824 0 1.413.588Q22 8.175 22 9v11q0 .824-.587 1.413A1.93 1.93 0 0 1 20 22zm0-2h16V9h-5q0 .825-.588 1.412A1.93 1.93 0 0 1 13 11h-2q-.825 0-1.412-.588A1.93 1.93 0 0 1 9 9H4zm2-2h6v-.45q0-.425-.238-.788a1.57 1.57 0 0 0-.662-.562 5 5 0 0 0-1.012-.337A5 5 0 0 0 9 15.75q-.575 0-1.088.113-.512.112-1.012.337a1.57 1.57 0 0 0-.663.563A1.4 1.4 0 0 0 6 17.55zm8-1.5h4V15h-4zM9 15q.624 0 1.063-.437.437-.438.437-1.063t-.437-1.062A1.45 1.45 0 0 0 9 12q-.625 0-1.062.438A1.45 1.45 0 0 0 7.5 13.5q0 .624.438 1.063Q8.374 15 9 15m5-1.5h4V12h-4zM11 9h2V4h-2z"
      ></path>
    </svg>
  );
};
