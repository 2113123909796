import React, { useState } from "react";
import { ButtonComponent } from "./ButtonComponent";
import { FilterIcon } from "../Assets";
import { ApplicationFilterModal } from "./ApplicationFilterModal";

interface FilterProps {
  phases: string[];
  statuses: string[];
  onFilterChange: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setIntakeYear: any;
  setIntakeMonth: any;
  setselectedAppStatus: any;
  setconfrimStatus: any;
  setApplications: any;
  reloadStudents: any;
  getStudentByStage?: any;
  setambassadorName?: any;
  ambassadors?: any;
  ambassadorName?: any;
  page?: any;
  users?: any;
  setselectedUser: any;
  selectedUser: any;
}

export const ApplicationFilter: React.FC<FilterProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [filterApplied, setfilterApplied] = useState(false);

  return (
    <div className="d-flex flex-row align-items-center">
      {filterApplied ? (
        <>
          <div
            onClick={() => setShowModal(true)}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#7F56D9",
              cursor: "pointer",
            }}
          >
            <FilterIcon
              color={"#7F56D9"}
              style={{ marginRight: 5, marginLeft: 23 }}
            />
            Edit Applications
          </div>
        </>
      ) : (
        <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
          <FilterIcon
            color={"#98A2B3"}
            style={{ marginRight: 5, marginLeft: 23 }}
          />
          Filters
        </div>
      )}

      <ApplicationFilterModal
        show={showModal}
        setfilterApplied={setfilterApplied}
        handleClose={() => setShowModal(false)}
        {...props}
      />
    </div>
  );
};
