// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
  padding: 11px 21px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #eaecf0;
`;

export const HeaderText = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #111826;
`;

export const Pointer = styled.span`
  background: rgba(0, 0, 0, 0.24);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 12px;
`;

export const AssignText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0065ff;
`;

export const UserLabelText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
`;

export const UserText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #253858;
`;

export const SideBar = styled.div`
  width: 100%;
  background: #fff;
  height: max-content;
  border-radius: 10px;
  padding: 12px;
  z-index: -1;
`;

export const Link = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 0px;
  background: ${(props) => props.color};
  padding: 8px 0px 8px 8px;
  border-radius: 8px;
  text-decoration: none;
`;
export const LinkText = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  margin-left: 12px;
`;
export const LinkBorder = styled.div`
  position: absolute;
  width: 4px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #7f56d9;
  border-radius: 0px 2px 2px 0px;
`;

export const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 10px;
  width: 100%;
  z-index: -1;

  overflow-y: scroll;
`;

export const HeaderCard = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 16px;
`;
